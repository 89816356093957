import {  Spinner } from "react-bootstrap";
import NavBar from "../../../NavBar";
import axios from "axios";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Updatesubcategory = () => {
  const { state } = useLocation();

  const navigate = useNavigate();

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [getallsubCategory, setGetAllSubcategory] = useState([]);
  const [getAllCategory, setGetAllCategory] = useState([]);
  const [name, setName] = useState(state.item.name);
  const [loading, setLoading] = useState(false);
  const [categoryId, setCategoryId] = useState(
    state.item.category_details[0]?._id
  );
  const [id, setId] = useState(state.item._id);
  const [status, setStatus] = useState(state.item.status);

  useEffect(() => {
    getAllSubCategory();
    getAllCat();
  }, []);
  const getAllSubCategory = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getAllSubCategory`, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          Version: "1.0.0",
        },
      })
      .then(function (response) {
        if (response.data.success) {
          setGetAllSubcategory(response.data.data);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const getAllCat = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getAllCategory`, {
        headers: {
          "x-access-token": `${token}`,
          version: "1.0.0",
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setGetAllCategory(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const UpdatesubCategory = () => {
    const obj = {
      name: name,
      CategoryId: categoryId,
      status: status,
    };
    setLoading(true);
    const token = localStorage.getItem("token");
    axios
      .put(`${BASE_URL}admin/updateSubCategory/${id}`, obj, {
        headers: {
          "x-access-token": `${token}`,
          " Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
          toast.error(response.data.message);
          setName("");
          getAllCat();
          getAllSubCategory();
          navigate("/SubCategory");
        } else {
          setLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.message);
      });
  };

  return (
    <>
      <NavBar />
      <div fluid style={{ height: "100vh", backgroundColor: "#f8f9fe;" }}>
        <div>
          <div className="mb-5">
            <div className="col col-md-1" ></div>
            <div>
              <div className="p-5 pt-3 mt-5 form">
                <div
                  className="dashboard mb-2 text-center"
                  style={{ fontSize: "25px" }}
                >
                  Update Sub Categories
                </div>

                <label className="pb-1 pt-3 ">
                  Category:<select
                  className="select text form-control"
                  style={{
                    backgroundColor: "#f8f9fe",
                    borderRadius: "5px",
                    padding: "10px",
                  }}
                  name="category"
                  value={categoryId}
                  onChange={(e) => setCategoryId(e.target.value)}
                >
                  <option> --select Category --</option>
                  {getAllCategory.map((item, index) => (
                    <option value={item._id} key={item._id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                </label>
                <label className="pb-1 pt-3 ">
                  SubCategory name:<input
                  type="text"
                  className="form-control text"
                  placeholder="Enter Subcategory Name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                /></label> 
                <label className="pb-1 pt-3 ">
                  Status:<select
                  name="status"
                  className="form-control text"
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
                </label>
                <button
                  type="button"
                  className="btn btn-primary mt-3"
                  onClick={() => UpdatesubCategory()}
                >
                  {" "}
                  Update {loading && <Spinner animation="border" size="sm" />}
                </button>
              </div>
            </div>
            <div className="col col-md-1" ></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Updatesubcategory;
