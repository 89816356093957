import { Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import NavBar from "../../../NavBar";
import { useLocation, useNavigate } from "react-router-dom";
const EditChildcategory = () => {
  const { state } = useLocation();
  console.log("child category", state);
  // const navigate = useNavigate()
  const [name, setName] = useState(state.item.name);
  const [status, setStatus] = useState(state.item.status);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [token, setToken] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [getallcategory, setGetAllCategory] = useState([]);
  const [id, setId] = useState("");
  const [categoryId, setCategoryId] = useState(state.item.CategoryId);
  const [categoryName, setCategoryName] = useState(
    state.item.category_details[0].name
  );
  const [subcategoryId, setSubCategoryId] = useState(state.item.SubCategoryId);
  const [subcategoryName, setSubCategoryName] = useState(
    state.item.SubCategory_details[0].name
  );
  const navigate = useNavigate();
  useEffect(() => {
    getAllCat();
    getSubCategory();
    let token = localStorage.getItem("token");
    if (token) {
      setToken(token);
    } else {
      navigate("/SignIn");
    }
  }, [token]);

  const Save = async () => {
    if (name.length == 0 && name == "") {
      toast.error("Child category name is required");
      return;
    }

    const fromData = new FormData();
    fromData.append("name", name);
    fromData.append("CategoryId", categoryId);
    fromData.append("SubCategoryId", subcategoryId);
    fromData.append("status", status);
    axios
      .post(`${BASE_URL}admin/addChildSubCategory`, fromData, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then(function (response) {
        if (response.data.success == false) {
          toast.error(response.data.message);
        } else {
          toast.success(response.data.message);
          setName("");
          getAllCat();
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const getSubCategory = () => {
    axios
      .post(
        `${BASE_URL}admin/getSubCategoryByCategoryId/${categoryId}`,
        {},
        {
          headers: {
            "x-access-token": `${token}`,
            "Content-Type": "Application/json",
            version: "1.0.0",
          },
        }
      )
      .then(function (response) {
        if (response.data.success == false) {
          setSubCategories("");
        } else {
          setSubCategories(response.data.data);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const getAllCat = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getAllCategory`, {
        headers: {
          "x-access-token": `${token}`,
          version: "1.0.0",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          if (response.data.error_code == 461) {
            navigate("/SignIn");
          }
        } else {
          setGetAllCategory(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const Fntosetsubc = (value, getSubCategory) => {
    setCategoryId(value);
    if (getSubCategory && typeof getSubCategory === "function") {
      getSubCategory();
    }
  };

  useEffect(() => {
    getSubCategory();
  }, [categoryId]);
  return (
    <>
      <NavBar />
      <Container fluid style={{ height: "100vh", backgroundColor: "#f8f9fe;" }}>
        <Container>
          <Row className="mb-5">
            <Col md={2}></Col>
            <Col className="p-3">
              <Row style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                <div className="p-5 mt-3 form">
                  <div
                    className="dashboard mb-2 heading"
                    style={{ fontSize: "25px" }}
                  >
                    Edit Child Category
                  </div>
                  <br />
                  <label className="py-2"> Category</label>
                  <Form.Select
                    aria-label="Default select example"
                    className="mb-3 select forminp"
                    name="categoryId"
                    value={categoryId}
                    onChange={(e) =>
                      Fntosetsubc(e.target.value, getSubCategory)
                    }
                  >
                    <option> --select Category --</option>
                    {getallcategory.map((item, index) => (
                      <option
                        selected={item.name == categoryName ? true : false}
                        value={item._id}
                        key={index}
                      >
                        {item.name}
                      </option>
                    ))}
                  </Form.Select>
                  <label className="formleb"> Subcategory</label>
                  <select
                    name="subcatgeory"
                    className="form-control forminp"
                    value={subcategoryId}
                    onChange={(e) => setSubCategoryId(e.target.value)}
                  >
                    <option value="">--Select SubCategory--</option>
                    {subCategories.length > 0 ? (
                      subCategories.map((item, index) => (
                        <option
                          selected={item.name == subcategoryName ? true : false}
                          value={item._id}
                          key={index}
                        >
                          {item.name}
                        </option>
                      ))
                    ) : (
                      <span>no record found</span>
                    )}
                  </select>
                  <br />
                  <label className="formleb">Childcategory Name:</label>
                  <input
                    type="text"
                    className="forminp form-control mb-3"
                    placeholder="Enter Childcategory name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <label className="formleb">Select Status</label>
                  <select
                    className="forminp form-control"
                    name="status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option selected>--Select status--</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                    <option></option>
                  </select>
                  <button
                    type="button"
                    className="formbtn btn btn-primary mt-3"
                    onClick={() => Save()}
                  >
                    {" "}
                    Save{" "}
                  </button>
                </div>
              </Row>
            </Col>
            <Col md={2}></Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};
export default EditChildcategory;
