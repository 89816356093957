import NavBar from "../../../NavBar";
import { useLocation } from "react-router-dom";
const Viewuser = () => {
  const state = useLocation();
  const item = state.state;
  console.log(state.state);

  return (
    <>
      <NavBar />
      <div className="container ">
        <div className="row d-flex justify-content-center align-items-center mt-5">
          <div
            className="col-10 p-5"
            style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
          >
            <div
              className="dashboard mb-2 text-center"
              style={{ fontSize: "25px" }}
            >
              User details
            </div>
            <div className="row">
              <div className="col-md-6">
                <label className="p-2">
                User Name:<input
                  type="text"
                  className="form-control"
                  name="name"
                  value={item.name}
                  disabled
                />
                </label>
              </div>

              <div className="col-md-6">
                <label className="p-2">
                Email: <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={item.email}
                  disabled
                />
                </label>
              </div>
              <div className="col-md-6">
                <label className="p-2">
                Mobile number:<input
                  type="text"
                  className="form-control"
                  name="name"
                  value={item.mobile_no}
                  disabled
                />
                </label>
              </div>
              <div className="col-md-6">
                <label className="p-2">
                  Address 1: <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={item.address}
                  disabled
                />
                </label>
              </div>
              <div className="col-md-6">
                <label className="p-2">
                  Address 2:<input
                  type="text"
                  className="form-control"
                  name="name"
                  value={item.address2}
                  disabled
                />
                </label>
              </div>

              <div className="col-md-6">
                <label className="p-2">
                  State: <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={item.state}
                  disabled
                />
                </label>
              </div>

              <div className="col-md-6">
                <label className="p-2">
                  City: <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={item.city}
                  disabled
                />
                </label>
              </div>

              <div className="col-md-6">
                <label className="p-2">
                  Pin code:<input
                  type="text"
                  className="form-control"
                  name="name"
                  value={item.zip_code}
                  disabled
                />
                </label>
              </div>

              <div className="col-md-6">
                <label className="p-2">
                  Status:<input
                  type="text"
                  className="form-control"
                  name="name"
                  value={item.status}
                  disabled
                />
               </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Viewuser;
