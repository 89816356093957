import Col from "react-bootstrap/Col";
import { Container, Row, Spinner } from "react-bootstrap";
import NavBar from "../../../NavBar";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useEffect } from "react";
import moment from "moment";
import { isDateValid } from "../../../functions/ValidateFn";
import { useLocation, useNavigate } from "react-router-dom";
const UpdateOffer = () => {
  const { state } = useLocation();
  const [start_date, setStart_date] = useState();
  const [end_date, setEnd_date] = useState();
  useEffect(() => {
    let srt_dt = moment(state.item.start_date).format("YYYY-MM-DD");
    let end_dt = moment(state.item.end_date).format("YYYY-MM-DD");
    setStart_date(srt_dt);
    setEnd_date(end_dt);
  }, []);

  const [couponCode, setCouponCode] = useState(state.item.coupon_code);
  const [offtype, setOfftype] = useState(state.item.type);
  const [percentage, setPercentage] = useState(state.item.offer);
  const [minAmount, setMinAmount] = useState(state.item.min_amount);
  const [loading, setLoading] = useState(false);

  const [status, setStatus] = useState(state.item.status);

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [id, setId] = useState(state.item._id);
  const navigate = useNavigate();

  const updateOffer = () => {
    let newDate = new Date();

    let newD = moment(newDate).format("YYYY-MM-DD");

    if (offtype == "" || offtype == undefined) {
      toast.error("Offer type is required");
      return;
    }
    if (percentage == "" || (percentage == undefined && isNaN(percentage))) {
      toast.error("Offer Amount or percentage is required.");
      return;
    }
    if (minAmount == "" || (minAmount == undefined && isNaN(minAmount))) {
      toast.error(" Minimum amount for the offer to be valid is required");
      return;
    }

    if (!isDateValid(start_date)) {
      toast.error("Enter valid start date.");
      return;
    }

    if (!isDateValid(end_date)) {
      toast.error("Enter valid end date.");
      return;
    }
    if (end_date <= start_date) {
      toast.error("Offer end date should be greater than the Offer start date");
      return;
    }
    if (couponCode == "" || couponCode.length == 0) {
      toast.error("Coupon code is required..");
      return;
    }
    if (couponCode.length < 4 || couponCode.length > 6) {
      toast.error("Coupon code  should have length between 4 to 6");
    }

    const token = localStorage.getItem("token");
    let obj = {
      start_date: start_date,
      end_date: end_date,
      offer: percentage,
      status: status,
      type: offtype,
      min_amount: minAmount,
      coupon_code: couponCode,
    };
    setLoading(true);
    axios
      .put(`${BASE_URL}admin/updateOffer/${id}`, obj, {
        headers: {
          "x-access-token": `${token}`,
          version: "1.0.0",
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          setLoading(false);

          toast.error(response.data.message);
        } else {
          toast.success(response.data.message);
          setLoading(false);
        }

        navigate("/add-offer");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.message);
      });
  };

  useEffect(() => {
    if (offtype == 1) {
      document.querySelector(".offerRateL").innerHTML = "Offer %";
    } else {
      document.querySelector(".offerRateL").innerHTML = "Offer Amount";
    }
  }, []);
  const callbackfn = (value) => {
    setOfftype(value);
    // console.log(value);
    if (value == 1) {
      document.querySelector(".offerRateL").innerHTML = "Offer %";
    }
    if (value == 2) {
      document.querySelector(".offerRateL").innerHTML = "Offer Amount";
    }
  };
  return (
    <>
      <NavBar />
      <div className="container-fluid"  style={{ height: "100vh", backgroundColor: "#f8f9fe;" }}>
        <div className="container">
          <div className="row mb-5">
            <div className="col col-md-1" ></div>
            <div className="col">
              <div className="p-5 pt-3 mt-5 form">
                <div
                  className="dashboard mb-2 text-center"
                  style={{ fontSize: "25px" }}
                >
                  Update Offer
                </div>
                <label className="mb-1 pt-3 ">Offer Type:</label>
                <select
                  className="text form-control"
                  onChange={(e) => callbackfn(e.target.value)}
                  value={offtype}
                >
                  <option value="">--select--</option>
                  <option value={1}>offer %</option>
                  <option value={2}>Flat offer</option>
                </select>
                <label className="mb-1 pt-3 offerRateL">Offer : </label>
                <input
                  type="number"
                  name="percentage"
                  value={percentage}
                  // value={name}
                  className="text form-control"
                  onChange={(e) => setPercentage(e.target.value)}
                />
                <label className="pt-3 mb-1 ">Offer Min Amount:</label>
                <input
                  type="number"
                  name="minAmount"
                  value={minAmount}
                  // value={name}
                  placeholder="Enter Minimum amount for the offer to be valid"
                  className="text form-control"
                  onChange={(e) => setMinAmount(e.target.value)}
                />
                <label className="mb-1 pt-3">Offer start date:</label>
                <input
                  type="date"
                  name="start_date"
                  value={start_date}
                  // value={name}
                  className="text form-control"
                  onChange={(e) => setStart_date(e.target.value)}
                />

                <label className=" mb-1 pt-3">Offer end date:</label>
                <input
                  type="date"
                  name="end_date"
                  value={end_date}
                  // value={name}
                  placeholder=""
                  className="text form-control"
                  onChange={(e) => setEnd_date(e.target.value)}
                />

                <label className="mb-1 pt-3">Coupon Code: </label>
                <input
                  type="text"
                  name="couponCode"
                  className="text form-control"
                  value={couponCode}
                  // value={name}
                  placeholder="Enter coupon code"
                  onChange={(e) => setCouponCode(e.target.value)}
                />

                <label className="mt-3 mb-1">Choose status:</label>
                <select
                  name="status"
                  className="text form-control "
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
                <button
                  type="button"
                  className="btn btn-primary mt-5 "
                  onClick={() => updateOffer()}
                >
                  Update{
                    loading&&<Spinner animation="border" size="sm"/>
                  }
                </button>
              </div>
            </div>
            <div className="col col-md-1"></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UpdateOffer;
