import Col from "react-bootstrap/Col";
import { Container, Row } from "react-bootstrap";
import NavBar from "../../../NavBar";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
const UpdateSize = () => {
  const { state } = useLocation();
  const [name, setName] = useState(state.item.name);
  const [status, setStatus] = useState(state.item.status);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [id, setId] = useState(state.item._id);
  const navigate = useNavigate();

  const updhsize = () => {
    const token = localStorage.getItem("token");
    const obj = {
      name: name,
      status: status,
    };
    axios
      .put(`${BASE_URL}admin/updateSize/${id}`, obj, {
        headers: {
          "x-access-token": `${token}`,
          version: "1.0.0",
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          toast.error(response.data.message);
        } else {
          toast.success(response.data.message);
        }
        setName("");
        navigate("/add-size");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <NavBar />
      <div className="container-fluid"  style={{ height: "100vh", backgroundColor: "#f8f9fe;" }}>
        <div className="contianer">
          <div  className="row mb-5">
            <div className="col col-md-1"></div>
            <div className="col">
              <div className="p-5 pt-3 mt-5 form">
                <div
                  className="dashboard mb-2 text-center"
                  style={{ fontSize: "25px" }}
                >
                  Update Size
                </div>
                <label className="mb-1 mt-3"> 
                Size <input
                  type="text"
                  placeholder="Enter Size"
                  className="text form-control"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                /></label>
                <label className="mt-3 mb-1">
                Status<select
                  name="status"
                  className="text form-control "
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
                </label>
                <button
                  type="button"
                  className="btn btn-primary mt-5"
                  onClick={() => updhsize()}
                >
                  {" "}
                  Update{" "}
                </button>
              </div>
            </div>
            <div className="col col-md-1" ></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UpdateSize;
