import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Container, Row, Spinner } from "react-bootstrap";
import NavBar from "../../../NavBar";
import Table from "react-bootstrap/Table";
import {  useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const HomeCategory = () => {
  const [name, setName] = useState("");
  const [status, setStatus] = useState("Active");
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [gethomeCategory, setGethomeCategory] = useState([]);
  const [loading , setLoading]= useState(false);
  const navigate = useNavigate();

  const AddHomeCategory = () => {
    const token = localStorage.getItem("token");
    if (name.length == 0 || name == "") {
      toast.error(" Homecategory name  is required");
      return;
    }
 
    setLoading(true)

    axios
      .post(`${BASE_URL}admin/addHomeCategory`, {
        name:name,
        status:status
      }, {
        headers: {
          "x-access-token": `${token}`,
          version: "1.0.0",
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setLoading(false)
          toast.success(response.data.message);
          setName("");
          getAllhomecategory();
        } else {
        
          setLoading(false)
          toast.error(response.data.message);
        }
      
      })
      .catch((error) => {
        setLoading(false)
        console.log(error.message);
      });
  };

  const getAllhomecategory = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getHomeCategory/${currentPage}`, {
        headers: {
          version: "1.0.0",
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setGethomeCategory(response.data.data);
          setPages(response.data.remainingCount);
        } else {
          if(response.data.error_code == 461) {
            navigate("/SignIn");
          }
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const deletehomecategory = (id) => {
    if (!(window.confirm("Do you really want to delete this item?"))) {
      return;
    } 
    const token = localStorage.getItem("token");
    axios
      .delete(`${BASE_URL}admin/deleteHomeCategory/${id}`, {
        headers: {
          version: "1.0.0",
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          toast.error(response.data.message);
          getAllhomecategory();
        } else {
          toast.error(response.data.message);
          if (response.data.error_code == 461) {
            navigate("/SignIn");
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getAllhomecategory();
  };

  useEffect(() => {
    getAllhomecategory();
  }, [currentPage]);

  return (
    <>
      <NavBar />
      <div className="container-fluid"  style={{ height: "100vh", backgroundColor: "#f8f9fe;" }}>
        <div className="container">
          <div  className="row mb-5">
            <div className="col col-md-1" ></div>
            <div className="col">
              <div className="p-5 mt-5 form">
                <div
                  className="dashboard mb-2 text-center"
                  style={{ fontSize: "25px" }}
                >
                  Home Category
                </div>

               
                  <label className="pb-1 pt-3 ">
                    Home Category name:
                    <input
                    type="text"
                    placeholder="Enter Home Category Name"
                    className="text d-block w-100"
                    style={{ outline:"none",border:"none" }}
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />  </label>
              
              
                <label className="pb-1 pt-3">
                  Status<select
                  name="status"
                  className="form-control text "
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select></label>

                <button
                  type="button"
                  className="btn btn-primary mt-3"
                  onClick={() => AddHomeCategory()}
                >
                  {" "}
                  Save{" "}{
                    loading&&<Spinner animation="border" size="sm"/>
                  }
                </button>
              </div>
            </div>
            <div className="col col-md-1" ></div>
          </div>
          <span style={{ fontSize: "20px", fontWeight: "600" }} className="p-2">
            Homecategory Listing
          </span>
          <div className="scrollit">
            <hr
              style={{ width: "165px", fontWeight: "700", color: "grey" }}
            ></hr>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>S no.</th>
                  <th> Home Category</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {gethomeCategory.map((item, index) => (
                  <tr key={item._id}>
                    <td className="tabletd">{++index}</td>
                    <td>{item.name}</td>
                    <td>{item.status}</td>
                    <td>
                      <button type="button" className="btn btn-primary btn-sm ">
                        <i
                          className="fa fa-edit"
                          onClick={() =>
                            navigate("/UpdHomecategory", { state: { item } })
                          }
                        ></i>{" "}
                      </button>
                      &nbsp; &nbsp;
                      <button type="button" class="btn btn-danger btn-sm ">
                        <span
                          className="fa fa-trash"
                          onClick={() => deletehomecategory(item._id)}
                        ></span>{" "}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
              {/* pagination  */}
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li
                    className={`page-item ${currentPage === 1 ? "disabled" : ""} `}
                  >
                    <button
                      className="page-link"
                    
                      aria-label="Previous"
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </button>
                  </li>

                  {Array.from({ length: pages }, (_, i) => i + 1).map(
                    (page) => (
                     
                        <li
                          key={page}
                          className={`page-item ${
                            page === currentPage ? "active" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => handlePageChange(page)}
                          >
                            {page}
                          </button>
                        </li>
                      
                    )
                  )}
                  <li
                    className={`page-item ${
                      currentPage === pages ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      aria-label="Next"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </button>
                  </li>
                </ul>
              </nav>
              {/* pagination end  */}
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};
export default HomeCategory;
