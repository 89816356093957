import axios from "axios";
import React, { useEffect, useState } from "react";
import NavBar from "../../../NavBar";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { Table } from "react-bootstrap";
const OrderDetails = () => {
  const state = useLocation();
  const item = state.state;
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [couponCode, setCouponCode] = useState("");

  const getCouponCode = (id) => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getCouponById/${id}`, {
        headers: {
          "x-access-token": `${token}`,
          version: "1.0.0",
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setCouponCode(response.data.data.coupon_code);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  useEffect(() => {
    getCouponCode(item.coupon_code_id);
  }, [item.coupon_code_id]);
  console.log(item);
  return (
    <>
      <NavBar />
      <div className="container ">
        <div className="row d-flex justify-content-center align-items-center mt-5">
          <div
            className="col-10 p-5"
            style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
          >
            <div
              className="dashboard mb-2 text-center"
              style={{ fontSize: "25px" }}
            >
              Order details
            </div>
            <div className="row">
              <div className="col-md-6">
                <label className="p-2">
                  OrderId:
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={item.user_Order_ID}
                    disabled
                  />
                </label>
              </div>

              <div className="col-md-6">
                <label className="p-2">
                  User Name:
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={item.name}
                    disabled
                  />
                </label>
              </div>

              <div className="col-md-6">
                <label className="p-2">
                  Email:
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={item.email}
                    disabled
                  />
                </label>
              </div>
              <div className="col-md-6">
                <label className="p-2">
                  Mobile number:
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={item.mobile_no}
                    disabled
                  />
                </label>
              </div>
              <div className="col-md-6">
                <label className="p-2">
                  Address 1:
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={item.address}
                    disabled
                  />
                </label>
              </div>
              <div className="col-md-6">
                <label className="p-2">
                  Address 2:
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={item.address2}
                    disabled
                  />
                </label>
              </div>
              <div className="col-md-6">
                <label className="p-2">
                  State:
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={item.state}
                    disabled
                  />
                </label>
              </div>

              <div className="col-md-6">
                <label className="p-2">
                  City:
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={item.city}
                    disabled
                  />
                </label>
              </div>

              <div className="col-md-6">
                <label className="p-2">
                  Pin code:
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={item.zip_code}
                    disabled
                  />
                </label>
              </div>
              {/* <div className="col-md-6">
                <label className="p-2">
                Coupon Code :<input
                  type="text"
                  className="form-control"
                  name="name"
                  value={couponCode}
                  disabled
                />
                </label>
              </div> */}

              <div className="col-md-6">
                <label className="p-2">
                  Amount :
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={item.amount}
                    disabled
                  />
                </label>
              </div>
              <div className="col-md-6">
                <label className="p-2">
                  Discount Amount :
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={item.discount_amount}
                    disabled
                  />
                </label>
              </div>

              <div className="col-md-6">
                <label className="p-2">
                  Total Amount :
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={item.total_amount}
                    disabled
                  />
                </label>
              </div>

              <div className="col-md-6">
                <label className="p-2">
                  Razorpay payment id :
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={item.razorpay_payment_id}
                  />
                </label>
              </div>

              <div className="col-md-6">
                <label className="p-2">
                  shipping charge:
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={item.shipping_charge}
                    disabled
                  />
                </label>
              </div>

              <div className="col-md-6">
                <label className="p-2">
                  Delivery Type:
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={item.delivery_type}
                    disabled
                  />
                </label>
              </div>

              <div className="col-md-6">
                <label className="p-2">
                  Payment status:
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={item.payment_status}
                    disabled
                  />
                </label>
              </div>

              <div className="col-md-6">
                <label className="p-2">
                  status:
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={item.status}
                    disabled
                  />
                </label>
              </div>
              <div className="col-md-6">
                <label className="p-2">
                  Order date:
                  <input
                    type="date"
                    className="form-control"
                    name="name"
                    value={moment(item.createdAt).format("YYYY-MM-DD")}
                    disabled
                  />
                </label>
              </div>
            </div>

            <div className="mt-5">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Product Image:</th>
                    <th>Product name:</th>

                    <th> Price:</th>
                    {/* <th> weight:</th> */}
                    {/* <th> Size:</th> */}
                    <th> Quantity:</th>
                  </tr>
                </thead>
                <tbody>
                  {item.Order_details?.length > 0 &&
                    item.Order_details.map((order, index) =>
                      order.Products_details?.map((product, index) => (
                        <tr key={item._id}>
                          <td>
                            <img
                              src={`${product?.image[0]}`}
                              alt={product?.name}
                              style={{ width: "40px", height: "50px" }}
                            />
                          </td>
                          <td>{product.name}</td>
                          <td>{product?.price}</td>
                          {/* <td>{order?.weight}</td> */}
                          {/* <td>{order?.size}</td> */}
                          <td>{order?.quantity}</td>
                        </tr>
                      ))
                    )}
                </tbody>
              </Table>
            </div>
            {/* cart end  */}
          </div>
        </div>
      </div>
    </>
  );
};
export default OrderDetails;
