import { Spinner } from "react-bootstrap";
import NavBar from "../../../NavBar";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
const UpdateHomeCategory = () => {
  const { state } = useLocation();
  const [name, setName] = useState(state.item.name);
  const [status, setStatus] = useState(state.item.status);
  const [loading, setLoading] = useState(false);

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [id, setId] = useState(state.item._id);
  const navigate = useNavigate();

  const updhcategory = () => {
    const token = localStorage.getItem("token");
    setLoading(true);
    axios
      .put(`${BASE_URL}admin/updateHomeCategory/${id}`, {
        name:name,
        status:status
      }, {
        headers: {
          "x-access-token": `${token}`,
          version: "1.0.0",
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
          setName("");
          navigate("/HomeCategory");
          toast.success(response.data.message);
        } else {
          setLoading(false);
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.message);
      });
  };

  return (
    <>
      <NavBar />
      <div className="container-fluid"  style={{ height: "100vh", backgroundColor: "#f8f9fe;" }}>
        <div className="container">
          <div  className="row mb-5">
            <div className="col col-md-1" ></div>
            <div className="col">
              <div className="p-5 mt-5 form">
                <div
                  className="dashboard mb-2 text-center"
                  style={{ fontSize: "25px" }}
                >
                  Update Home Category
                </div>
                <label className="pb-1 pt-3">
                Homecategory name:<input
                  type="text"
                  placeholder="Enter Homecategory Name"
                  className="text form-control"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                /></label>
                <label className="mt-3">
                  Status <select
                  name="status"
                  className="form-control text  "
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
                </label>
                <button
                  type="button"
                  className="btn btn-primary mt-5"
                  onClick={() => updhcategory()}
                >
                  {" "}
                  Update{" "}{
                    loading&&<Spinner animation="border" size="sm"/>
                  }
                </button>
              </div>
            </div>
            <div className="col col-md-1"></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UpdateHomeCategory;
