import Dashboard from "./Dashboard";

import Category from "./Pages/Module/Categories/Category";
import SubCategory from "./Pages/Module/Subcategory/subCategory";
import HomeCategory from "./Pages/Module/Homecatgory/Homecategory";
import AddProduct from "./Pages/Module/product/Addproduct";
import ProductListing from "./Pages/Module/product/ProductListing";
import { route, createBrowserRouter, RouterProvider } from "react-router-dom";
import Slider from "./Pages/Module/Slider/Slider";
import Banner from "./Pages/Module/Homebanner/Banner";
import WebsiteInfo from "./Pages/Module/WebsiteInfo";
import AllOrder from "./Pages/Module/Orders/AllOrder";
import NewOrder from "./Pages/Module/Orders/NewOrder";
import Process from "./Pages/Module/Orders/Process";
import Shipped from "./Pages/Module/Orders/Shipped";
import Delivered from "./Pages/Module/Orders/Delivered";
import Failed from "./Pages/Module/Orders/Failed";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboardd from "./Dashboard";
import SignIn from "./Pages/Module/SignIn";
import ShippingCharges from "./Pages/Module/shippingCharges";
import AllUserList from "./Pages/Module/users/Alluserlist";
import StaticPageList from "./Pages/Module/StaticPages/staticPageList";
import Changepswd from "./Pages/Module/Changepassword";
import EditCategory from "./Pages/Module/Categories/Editcategory";
import UpdateProduct from "./Pages/Module/product/UpdateProduct";
import Updatesubcategory from "./Pages/Module/Subcategory/Updatesubcategory";
import UpdateHomeCategory from "./Pages/Module/Homecatgory/UpdateHomeCategory";
import UpdateSlider from "./Pages/Module/Slider/UpdateSlider";
import UpdateStaticpage from "./Pages/Module/StaticPages/UpdateStaticpage";
import Offer from "./Pages/Module/Offer/AddOffer";
import UpdateOffer from "./Pages/Module/Offer/UpdateOffer";
import Testimonial from "./Pages/Module/Testimonial/Testimonial";
import UpdateSize from "./Pages/Module/Size/UpdateSize";
import Size from "./Pages/Module/Size/Size";
import Viewuser from "./Pages/Module/users/ViewUser";
import OrderDetails from "./Pages/Module/Orders/OrderDetails";
import ReturnOrders from "./Pages/Module/Orders/ReturnOrders";
import ReplaceOrders from "./Pages/Module/Orders/ReplaceOrders";
import PrintInvoice from "./Pages/Module/Orders/PrintInvoice";
import ProductView from "./Pages/Module/product/ProductView";
import Childcategory from "./Pages/Module/childCategory/Childcategory";
import EditChildcategory from "./Pages/Module/childCategory/Editchildcategory";

function App() {
  const router = createBrowserRouter([
    {
      path: "/SignIn",
      element: <SignIn />,
    },
    {
      path: "/",
      element: <Dashboard />,
    },
    {
      path: "/view-user",
      element: <Viewuser />,
    },
    {
      path: "/order-details",
      element: <OrderDetails />,
    },
    {
      path: "/Category",
      element: <Category />,
    },
    {
      path: "/SubCategory",
      element: <SubCategory />,
    },
    {
      path: "/ChildCategory",
      element: <Childcategory />,
    },
    {
      path: "/Edit-ChildCategory",
      element: <EditChildcategory />,
    },
    {
      path: "/HomeCategory",
      element: <HomeCategory />,
    },
    {
      path: "/AddProduct",
      element: <AddProduct />,
    },
    {
      path: "/ProductListing",
      element: <ProductListing />,
    },
    {
      path: "/WebsiteInfo",
      element: <WebsiteInfo />,
    },
    {
      path: "/Slider",
      element: <Slider />,
    },
    {
      path: "/Banner",
      element: <Banner />,
    },
    {
      path: "/AllOrder",
      element: <AllOrder />,
    },
    {
      path: "/new-order",
      element: <NewOrder />,
    },
    {
      path: "/process",
      element: <Process />,
    },
    {
      path: "/shipped",
      element: <Shipped />,
    },
    {
      path: "/delivered",
      element: <Delivered />,
    },
    {
      path: "/failed",
      element: <Failed />,
    },
    {
      path: "/return-orders",
      element: <ReturnOrders />,
    },
    {
      path: "/replace-orders",
      element: <ReplaceOrders />,
    },
    {
      path: "/shippingcharges",
      element: <ShippingCharges />,
    },
    {
      path: "/alluserlist",
      element: <AllUserList />,
    },
    {
      path: "/staticpageList",
      element: <StaticPageList />,
    },
    {
      path: "/update-staticpage",
      element: <UpdateStaticpage />,
    },
    {
      path: "/change-password",
      element: <Changepswd />,
    },
    {
      path: "/edit_category",
      element: <EditCategory />,
    },
    {
      path: "/update-Product",
      element: <UpdateProduct />,
    },
    {
      path: "/Update-subcategory",
      element: <Updatesubcategory />,
    },
    {
      path: "/UpdHomecategory",
      element: <UpdateHomeCategory />,
    },
    {
      path: "/update-slider",
      element: <UpdateSlider />,
    },
    {
      path: "/add-offer",
      element: <Offer />,
    },
    {
      path: "/update-offer",
      element: <UpdateOffer />,
    },
    {
      path: "/testimonial",
      element: <Testimonial />,
    },
    {
      path: "/update-size",
      element: <UpdateSize />,
    },
    {
      path: "/add-size",
      element: <Size />,
    },

    {
      path: "/print-invoice",
      element: <PrintInvoice />,
    },
    {
      path: "/product-view",
      element: <ProductView />,
    },
  ]);

  return (
    <>
      <ToastContainer />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
