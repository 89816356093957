import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import NavBar from "../../../NavBar";
import { Link, useNavigate } from "react-router-dom";

const StaticPageList = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem("token");
  
  const [staticpginfo, setStaticpginfo] = useState([]);
  const navigate = useNavigate();
  const getStaticpg = () => {
    axios
      .post(
        `${BASE_URL}admin/getStaticPages`,
        {},
        {
          headers: {
            "Content-Type": "Application/json",
            "x-access-token": `${token}`,
            version: "1.0.0",
          },
        }
      )
      .then((response) => {
        if (response.data.success === false) {
          if (response.data.error_code == 461) {
            navigate("/SignIn");
          }
        } else {
          setStaticpginfo(response.data.data);
          console.log(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deletestcpg = (id) => {
    if (!(window.confirm("Do you really want to delete this item?"))) {
      return;
    } 
    axios
      .delete(`${BASE_URL}admin/deleteStaticPage/${id}`, {
        headers: {
          "Content-Type": "Application/json",
          version: "1.0.0",
          "x-access-token": `${token}`,
        },
      })
      .then((response) => {
        if (response.data.success) {
         
          getStaticpg();
          toast.error(response.data.message);
        } else {
          toast.error(response.data.message);

          if (response.data.error_code == 461) {
            navigate("/SignIn");
          }
         
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };


  const AddStaticPage = () => {
    const token = localStorage.getItem('token');
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}admin/addStaticPage`,
        {},
        {
          headers: {
            version: '1.0.0',
            'x-access-token': `${token}`,
            'Content-Type': 'Appliction/json',
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          console.log('static page added successfully');
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  useEffect(() => {
    getStaticpg();
    AddStaticPage();
  }, []);

  return (
    <>
      <NavBar />
     
      <div className="container">
        <div className="pt-4" style={{overflow:"auto"}}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S no</th>
                <th> Name</th>
                <th> description</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {staticpginfo.map((item, index) => (
                <tr key={index}>
                  <td className="tabletd">{++index}</td>

                  <td>{item.name}</td>
                  <td>{item.description}</td>
                  <td>{item.status}</td>

                  <td>
                    <button type="button" className="btn btn-primary ">
                      <i
                        className="fa fa-edit"
                        onClick={() =>
                          navigate("/update-staticpage", { state: { item } })
                        }
                      ></i>{" "}
                    </button>
                    &nbsp; &nbsp;
                    <button
                      type="button"
                      className="btn btn-danger "
                      onClick={() => deletestcpg(item._id)}
                    >
                      <i className="fa fa-trash"></i>{" "}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};
export default StaticPageList;
