import axios from "axios";
import {  Spinner } from "react-bootstrap";
import NavBar from "../../../NavBar";
import Table from "react-bootstrap/Table";
import moment from "moment";
import { useState,useEffect } from "react";
import { toast } from "react-toastify";
import { isDateValid } from "../../../functions/ValidateFn";

import { useNavigate } from "react-router-dom";
const Offer = () => {
  const [offtype, setOfftype] = useState();
  const [couponCode, setCouponCode] = useState("");
  const [minAmount, setMinAmount] = useState();
  const [percentage, setPercentage] = useState();
  const [status, setStatus] = useState("Active");
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [getOffers, setGetOffers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState();
  const [loading , setLoading]=useState(false);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const AddOffer = () => {
    let newDate = new Date();
    let newD = moment(newDate).format("YYYY-MM-DD");

    if (offtype == "" || offtype == undefined) {
      toast.error("Offer type is required");
      return;
    }
    if (percentage == "" || (percentage == undefined && isNaN(percentage))) {
      toast.error("Offer Amount or percentage is required.");
      return;
    }
    if (minAmount == "" || (minAmount == undefined && isNaN(minAmount))) {
      toast.error(" Minimum amount for the offer to be valid is required");
      return;
    }

    if (!isDateValid(start_date)) {
      toast.error("Enter valid start date.");
      return;
    }

    if (start_date < newD) {
      toast.error("Offer Start date should be greater than the current date.");
      return;
    }

    if (!isDateValid(end_date)) {
      toast.error("Enter valid end date.");
      return;
    }
    if (end_date <= start_date) {
      toast.error("Offer end date should be greater than the Offer start date");
      return;
    }
    if (couponCode == "" || couponCode.length == 0) {
      toast.error("Coupon code is required..");
      return;
    }
    if (couponCode.length < 4 || couponCode.length > 6) {
      toast.error("Coupon code  should have length between 4 to 6");
    }

    let obj = {
      start_date: start_date,
      end_date: end_date,
      offer: percentage,
      status: status,
      type: offtype,
      min_amount: minAmount,
      coupon_code: couponCode,
    };
    const token = localStorage.getItem("token");
    axios
      .post(`${BASE_URL}admin/addOffer`, obj, {
        headers: {
          "x-access-token": `${token}`,
          version: "1.0.0",
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          setLoading(false);
          toast.error(response.data.message);
        }else{
          toast.error(response.data.message);
          setLoading(false)
        }
      })
      .catch((error) => {
        console.log(error.message);
        setLoading(false)
      });
  };
  const getAllOffers = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getOffers/${currentPage}`, {
        headers: {
          version: "1.0.0",
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          if (response.data.error_code == 461) {
            navigate("/SignIn");
          }
        } else {
          setGetOffers(response.data.data);
          setPages(response.data.remainingCount);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getAllOffers();
  };

  const deleteoffer = (id) => {
    if (!(window.confirm("Do you really want to delete this item?"))) {
      return;
    } 
    const token = localStorage.getItem("token");
    axios
      .delete(`${BASE_URL}admin/deleteOfferById/${id}`, {
        headers: {
          version: "1.0.0",
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          toast.error(response.data.message);
          getAllOffers();
        } else {
          toast.error(response.data.message);
          if (response.data.error_code == 461) {
            navigate("/SignIn");
          }
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  useEffect(() => {
    getAllOffers();
  }, [currentPage]);

  const callbackfn = (value) => {
    setOfftype(value);
    if (value == 1) {
      document.querySelector(".offerRateL").innerHTML = "Offer %";
    }
    if (value == 2) {
      document.querySelector(".offerRateL").innerHTML = "Offer Amount";
    }
  };
  return (
    <>
      <NavBar />
      <div className="container-fluid"  style={{ height: "100vh", backgroundColor: "#f8f9fe;" }}>
        <div className="container">
          <div  className="row mb-5">
            <div className="col col-md-1" ></div>
            <div className="col">
              <div className="p-5 pt-3 mt-5 form">
                <div
                  className="dashboard mb-2 text-center "
                  style={{ fontSize: "25px" }}
                >
                  Add Offer
                </div>
                <label className="mb-1 pt-3">
                Offer Type<select
                  className="text form-control"
                  onChange={(e) => callbackfn(e.target.value)}
                  value={offtype}
                >
                  <option value="">--select--</option>
                  <option value={1}>offer %</option>
                  <option value={2}>Flat offer</option>
                </select>
                </label>
                <label className="offerRateL mb-1 pt-3">
                Offer % :<input
                  type="number"
                  name="percentage"
                  value={percentage}
                  placeholder="Enter Offer "
                  className="text form-control"
                  onChange={(e) => setPercentage(e.target.value)}
                />
</label>
                <label className="mb-1 pt-3">
                Offer Min Amount: <input
                  type="number"
                  name="minAmount"
                  value={minAmount}
                  placeholder="Minimum amount for the offer to be valid"
                  className="text form-control"
                  onChange={(e) => setMinAmount(e.target.value)}
                />
</label>
                <label className="mb-1 pt-3">
                 Offer start date:<input
                  type="date"
                  name="start_date"
                  value={start_date}
                  className="text form-control"
                  onChange={(e) => setStart_date(e.target.value)}
                />
</label>
                <label className="mb-1 pt-3">
                Offer end date: <input
                  type="date"
                  name="end_date"
                  value={end_date}
                  className="text form-control"
                  onChange={(e) => setEnd_date(e.target.value)}
                />
</label>
                <label className="mb-1 pt-3">
                  Coupon code:<input
                  type="text"
                  className="text form-control"
                  name="couponCode"
                  value={couponCode}
                  // value={name}
                  placeholder="Enter coupon code "
                  onChange={(e) => setCouponCode(e.target.value)}
                />
                 </label>

                <label className="mb-1 mt-3">
                Status<select
                  name="status"
                  className="text form-control "
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
                </label>
                <button
                  type="button"
                  className="btn btn-primary mt-5"
                  onClick={() => AddOffer()}
                >
                  {" "}
                  Save{" "}{
                    loading&&<Spinner animation="border" size="sm"/>
                  }
                </button>
              </div>
            </div>
            <div className="col col-md-1" ></div>
          </div>
          <div className="scrollit">
            <span
              style={{ fontSize: "20px", fontWeight: "600" }}
              className="p-2"
            >
              Offer Listing
            </span>
            <hr
              style={{ width: "165px", fontWeight: "700", color: "grey" }}
            ></hr>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>S. no</th>
                  <th>Offer type</th>
                  <th>Offer Min Amount</th>
                  <th>Offer Coupon code</th>
                  <th> Start date</th>
                  <th>End date</th>
                  <th>Offer rate</th>
                  <th>Status</th>
                  <th>Active</th>
                </tr>
              </thead>
              <tbody>
                {getOffers?.length > 0 &&
                  getOffers.map((item, index) => (
                    <tr key={item._id}>
                      <td className="tabletd">{++index}</td>
                      {/* <td>{item.type}</td> */}
                      <td>{item.type == 1 ? "Offer(%)" : "Flat offer"}</td>
                      <td>{item.min_amount}</td>
                      <td>{item.coupon_code}</td>

                      <td>{moment(item.start_date).format("YYYY-MM-DD")}</td>
                      <td>{moment(item.end_date).format("YYYY-MM-DD")}</td>
                      <td>{item.offer}</td>
                      <td> {item.status}</td>
                      {/* <td>{item.status}</td> */}

                      <td>
                        <button type="button" class="btn btn-primary btn-sm   ">
                          <i
                            className="fa fa-edit"
                            onClick={() =>
                              navigate("/update-offer", { state: { item } })
                            }
                          ></i>{" "}
                        </button>
                        &nbsp; &nbsp;
                        <button type="button" class="btn btn-danger btn-sm">
                          <i
                            className="fa fa-trash"
                            onClick={() => deleteoffer(item._id)}
                          ></i>{" "}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>

              {/* pagination  */}
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li
                    className={`page-item ${currentPage === 1 ? "disabled" : ""} `}
                  >
                    <button
                      className="page-link"
                  
                      aria-label="Previous"
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </button>
                  </li>

                  {Array.from({ length: pages }, (_, i) => i + 1).map(
                    (page) => (
                    
                        <li
                          key={page}
                          className={`page-item ${
                            page === currentPage ? "active" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => handlePageChange(page)}
                          >
                            {page}
                          </button>
                        </li>
                    
                    )
                  )}
                  <li
                    className={`page-item ${
                      currentPage === pages ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      
                      aria-label="Next"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </button>
                  </li>
                </ul>
              </nav>
              {/* pagination end  */}
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};
export default Offer;
