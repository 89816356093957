
// import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Container, Row, Spinner } from "react-bootstrap";
import NavBar from '../../../NavBar';
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';


const SubCategory = () => {
  const navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [getallsubCategory, setGetAllSubcategory] = useState([]);
  const [getallcategory, setGetAllCategory] = useState([]);
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [id, setId] = useState("");
  const [status, setStatus] = useState("Active");
  const [categoryid, setCategoryId] = useState('');
  const [loading , setLoading]=useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState();

  useEffect(() => {
    getAllSubCategory();
    getAllCat();
  }, [])
  const getAllSubCategory = () => {

    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getSubCategory/${currentPage}`, {

        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          "Version": "1.0.0"
        },

      })
      .then(function (response) {

        if (response.data.success) {
          setGetAllSubcategory(response.data.data);
          setPages(response.data.remainingCount);
        }
       
      })
      .catch((error) => {
        toast.error(error);
      })
  }


  const getAllCat = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getAllCategory`, {
        headers: {
          "x-access-token": `${token}`,
          "version": "1.0.0",
          "Content-Type": "multipart/form-data"
        }
      }).then((response) => {
        if (response.data.success == false) {
        } else {
          setGetAllCategory(response.data.data);
        }
      }).catch((error) => {
        console.log(error);
      })
  }
  const save = () => {
    if (!categoryid) {
      toast.error("Please select category.");
      return;
    }
    if (name.length < 0 || name == "") {
      toast.error("Subcategory name is required");
      return;
    }

    const obj = {
      name: name,
      CategoryId: categoryid,
      status: status

    }
    const token = localStorage.getItem("token");
    setLoading(true);
    axios
      .post(`${BASE_URL}admin/addSubCategory`, obj, {
        headers: {
          "x-access-token": `${token}`,
          " Content-Type": "Application/json",
          "version": "1.0.0"
        }
      }).then((response) => {
        if (response.data.success == false) {
          setLoading(false);
          toast.error(response.data.message);
        }
        else {
          toast.success(response.data.message);
          setName("");
          getAllCat();
          setLoading(false);

          getAllSubCategory();
        }
      }).catch((err)=>{
        console.log(err.message);
        setLoading(false);
      })
  }
  
  const deleteSubcategory = (id) => {
    if (window.confirm("Do you really want to delete this item?")) {

    } else {

      return;
    }
    const token = localStorage.getItem("token");
    axios
      .delete(`${BASE_URL}admin/deleteSubCategory/${id}`, {
        headers: {
          "version": "1.0.0",
          "x-access-token": `${token}`,
          "Content-Type": "Application/json"
        }
      }).then((response) => {
        if (response.data.success == false) {
          toast.error(response.data.message);
        } else {
          toast.error(response.data.message);
          getAllSubCategory();
        }
      }).catch((error) => {
        console.log(error);
      })
  }


  const handlePageChange = (page) => {
    setCurrentPage(page);
    getAllCat();

  }
  useEffect(() => {
    getAllSubCategory();
    getAllCat();
  }, [currentPage])
  return (
    <>
      <NavBar />
      <div className='container-fluid'  style={{ height: '100vh', backgroundColor: '#f8f9fe;' }}>
        <div className='container'>
          <div  className="row mb-5">
            <div className='col col-md-1'>
            </div>
            <div className='col' >
              <div className="p-5 mt-5 form" >
                <div className="dashboard mb-2 text-center" style={{ fontSize: '25px' }}>
                  Add Sub Categories

                </div>

                <label className="pb-1 pt-3 ">
                Category: <select
                  name="category"
                  className="text form-control "
                  value={categoryid}
                  onChange={(e) => setCategoryId(e.target.value)}
                  style={{ backgroundColor: '#f8f9fe', borderRadius: '5px', padding: '10px' }}
                >
                  <option> --select Category --</option>
                  {
                    getallcategory.map((item, index) => (
                      <option value={item._id} key={item._id}
                      >{item.name}</option>
                    ))
                  }
                </select>
                </label>
                <label className="pb-1 pt-3 ">
                  SubCategory name:<input type='text' className='text form-control ' placeholder='Name'
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                /></label>
                <label className="pb-1 pt-3 "> 
                Status: <select
                  name="status"
                  className="text form-control "
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select> </label>
                
                <button type="button" className="btn btn-primary mt-3" onClick={() => save()}> Save {loading&&<Spinner animation='border' size="sm"/>}</button>
              </div>
            </div>
            <div className='col col-md-1' >
            </div>
          </div>
          <span style={{ fontSize: '20px', fontWeight: '600' }} className="">Subcategory Listing</span>
          <div className="scrollit">
            <hr style={{ width: '165px', fontWeight: '700', color: 'grey', margin: "10px" }}></hr>
            <Table striped bordered hover >
              <thead>
                <tr>
                <th>S no.</th>

                  <th>Category</th>
                  <th> Subcategory</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  getallsubCategory.map((item, index) => (
                    <tr key={item._id}>
                      <td className='tabletd'>{++index}</td>

                      <td>{item.category_details[0]?.name}</td>
                      <td>{item.name}</td>
                      <td>{item.status}</td>
                      <td>
                        <button type="button" className="btn btn-primary btn-sm "
                          onClick={() => navigate("/Update-subcategory", { state: { item } })}
                        ><i className="fa fa-edit"></i> </button>
                        &nbsp; &nbsp;
                        <button type="button" className="btn btn-danger btn-sm "><i className='fa fa-trash' onClick={() => deleteSubcategory(item._id)}></i> </button>
                      </td>
                    </tr>

                  )
                  )
                }


              </tbody>
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className={`page-item ${currentPage === 1 ? "disabled" : ""} `}>
                    <button className="page-link" href="#" aria-label="Previous" onClick={() => handlePageChange(currentPage - 1)}>
                      <span aria-hidden="true">&laquo;</span>
                    </button>
                  </li>
                

                  {Array.from({ length: pages }, (_, i) => i + 1).map((page) => (
                
                      <li key={page} className={`page-item ${page === currentPage ? "active" : ""}`}>
                        <button className="page-link" onClick={() => handlePageChange(page)}>
                          {page}
                        </button>
                      </li>
                 
                  ))}
                  <li className={`page-item ${currentPage === pages ? "disabled" : ""}`}>
                    <button className="page-link" aria-label="Next" onClick={() => handlePageChange(currentPage + 1)}>
                      <span aria-hidden="true">&raquo;</span>
                    </button>
                  </li>
                </ul>
              </nav>
            </Table>
          </div>
        </div>
      </div>

    </>
  );
}
export default SubCategory;