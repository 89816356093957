import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import NavBar from "../../../NavBar";
import { useLocation, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

const UpdateSlider = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [name, setName] = useState(state.item.name);
  const [file, setFile] = useState("");
  const [status, setStatus] = useState(state.item.status);
  const [title, setTitle] = useState(state.item.title);
  const [heading, setHeading] = useState(state.item.heading1);
  const [slug, setSlug] = useState(state.item.slug);
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(state.item._id);
  const maxFileSize = 3000000;
  const updSlider = () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("status", status);
    formData.append("title", title);
    formData.append("heading1", heading);
    formData.append("image", file[0]);
    setLoading(true);
    axios
      .post(`${BASE_URL}admin/updateSlider/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          version: "1.0.0",
          "x-access-token": `${token}`,
        },
      })
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
          toast.success(response.data.message);
          setId("");
          setName("");
          setSlug("");
          navigate("/Slider");
        } else {
          setLoading(false);
          toast.error(response.data.message);
         
        }
      })
      .catch((err) => {
        console.log(err.message);
        setLoading(false);
      });
  };

  return (
    <>
      <NavBar />
      <div className="container ">
        <div className="row d-flex justify-content-center align-items-center mt-5">
          <div
            className="col-10 p-5"
            style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
          >
            <div className="row">
              <div className="col-md-6">
                <label className="py-2">
                Name<input
                  type="text"
                  className="form-control"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                </label>
              </div>
              <div className="col-md-6">
                <label className="py-2">
                  Image <input
                  type="file"
                  className="form-control"
                  accept=".png , .jpeg , .jpg "
                  name="file"
                  onChange={(e) => setFile(e.target.files)}
                />
                </label>
                <img src={state.item.image} alt={state.item.name} width={100} height={50} />
              </div>

              <div className="col-md-6">
                <label className="py-2">
                Title<input
                  type="text"
                  className="form-control"
                  name="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                </label>
              </div>
              <div className="col-md-6">
                <label className="py-2">
               heading<input
                  type="text"
                  className="form-control"
                  name="heading"
                  value={heading}
                  onChange={(e) => setHeading(e.target.value)}
                />
                </label>
              </div>

              <div className="col-md-6">
                <label className="py-2">
                  Slug<input
                  type="text"
                  className="form-control"
                  name="slug"
                  value={slug}
                  onChange={(e) => setSlug(e.target.value)}
                /></label>
              </div>

              <div className="col-md-6">
                <label className="py-2">
                  Status<select
                  className="form-control"
                  name="status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option>--select--</option>
                  <option>Active</option>
                  <option>Inactive</option>
                </select>
                </label>
              </div>
              <button
                type="submit"
                className="btn btn-sm btn-primary mt-3 ms-3"
                style={{ width: "60px" }}
                onClick={() => updSlider()}
              >
                Save {
                    loading&&<Spinner  animation="border" size="sm"/>
                }
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UpdateSlider;
