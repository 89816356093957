import NavBar from "../../../NavBar";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Table } from "react-bootstrap";

const ProductView = () => {
  const { state } = useLocation();
  const state2 = state.item;
  useEffect(() => {
    console.log(state.item);
  }, []);

  return (
    <>
      <NavBar />
      <div className="container  ">
        <div className="row d-flex justify-content-center">
          <div  className="col-10 p-5"
            style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
              <h2>Product View</h2>
            <div className="row ">
              <div className="col-md-6">
                <label className="formleb">Category</label>
                <input
                  className="form-control forminp"
                  value={state2.category_details[0]?.name}
                  disabled
                />
              </div>
              <div className="col-md-6">
                <label className="formleb">Choose Subcategory</label>
                <input
                  className="form-control forminp"
                  value={state2.subCategory_details[0]?.name}
                  disabled
                />
              </div>

              <div className="col-md-6">
                <label className="formleb">Choose HomeCategory</label>
                <input
                  className="form-control forminp"
                  value={state2.homeCategory_details[0]?.name}
                  disabled
                />
              </div>

              <div className="col-md-6">
                <label className="formleb">Product name</label>
                <input
                  type="text"
                  className="form-control forminp"
                  name="name"
                  value={state2?.name}
                  disabled
                />
              </div>

              <div className="col-md-6">
                <label className="formleb">Price</label>
                <input
                  type="text"
                  className="form-control forminp"
                  name="price"
                  value={state2?.price}
                  disabled
                />
              </div>
              <div className="col-md-6">
                <label className="formleb">Quantity</label>
                <input
                  type="text"
                  className="form-control forminp"
                  name="quantity"
                  value={state2?.quantity}
                  disabled
                />
              </div>
              <div className="col-md-6">
                <label className="formleb"> images</label>
                {state2.image.map((item, index) => (
                  <img
                    src={item}
                    className="img-fluid"
                    width={100}
                    key={index}
                    height={80}
                  />
                ))}
              </div>
              <div className="col-md-6">
                <label className="formleb">Short description</label>

                <CKEditor
                  disabled
                  className="forminp"
                  editor={ClassicEditor}
                  data={state2?.short_decription}
                />
              </div>
              <div className="col-md-6">
                <label className="formleb">Long description</label>

                <CKEditor
                  disabled
                  className="forminp"
                  editor={ClassicEditor}
                  data={state2?.long_decription}
                  onReady={(editor) => {
                    console.log("Editor is ready to use!", editor);
                  }}
                />
              </div>

              <div className="col-md-6">
                <label className="formleb">Status</label>
                <input
                  type="text"
                  className="form-control forminp"
                  value={state2?.status}
                  disabled
                />
              </div>
              <div className="col-md-6">
                <label className="formleb">
                  Check mark if you want to add this product in
                </label>
                <br />
                <div className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    class="largerCheckbox"
                    checked={state2?.bestseller}
                    disabled
                  />
                  &nbsp;&nbsp;&nbsp; <span>Bestseller</span>&nbsp;&nbsp;&nbsp;
                </div>
              </div>

              <div className="col-md-6">
                <label className="formleb">
                  Check mark if you want to add this product in
                </label>
                <br />
                <div className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    class="largerCheckbox"
                    checked={state2?.newarrivals}
                    disabled
                  />
                  &nbsp;&nbsp;&nbsp; <span>New Arrivals</span>&nbsp;&nbsp;&nbsp;
                </div>
              </div>

              <div className="col-12 pt-5">
              <div style={{ overflowX: "auto" }}>
                    <Table striped bordered hover>
                      {state2.product_varients?.length > 0 ? (
                        <>
                          {" "}
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Size</th>
                              <th>Weight</th>
                              <th>Price</th>
                              <th>default</th>
                            </tr>
                          </thead>
                          <tbody>
                            {state2.product_varients.length > 0
                              ? state2.product_varients.map((item, index) => (
                               
                                    <tr key={item._id}>
                                      <td>{index + 1}</td>
                                      <td>{item.size}</td>
                                      <td>
                                        {item.weightnprice.length > 0 &&
                                          item.weightnprice.map(
                                            (item1, index1) => (
                                              <div key={index1}>
                                                {item1.weight}
                                              </div>
                                            )
                                          )}
                                      </td>
                                      <td>
                                        {item.weightnprice.length > 0 &&
                                          item.weightnprice.map(
                                            (item1, index1) => (
                                              <div key={index1}>
                                                {item1.price}
                                              </div>
                                            )
                                          )}
                                      </td>

                                      <td>
                                        {item.set_default ? "True" : "false"}
                                      </td>
                                  
                                 
                                    </tr>
                                 
                                ))
                              : ""}
                          </tbody>
                        </>
                      ) : (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ height: "200px", width: "100%" }}
                        >
                          <div>No record Added</div>
                        </div>
                      )}
                    </Table>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProductView;
