import { Container, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import NavBar from "../../../NavBar";
import { useNavigate } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';

const Category = () => {
  const [name, setName] = useState("");
  const [file, setFile] = useState("");
  const fileInputRef = useRef(null);
  const [status, setStatus] = useState("");
  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [token, setToken] = useState("");
  const [getAllCategory, setGetAllCategory] = useState([]);
  const [loading , setLoading]=useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token) {
      setToken(token);
    } else {
      navigate("/SignIn");
    }
  }, [token]);

  const Save = async (data) => {
    if (name.length == 0 && name == "") {
      toast.error("Category name is required");
      return;
    }
    if (heading.length == 0 || heading == "") {
      toast.error(" Category heading  is required");
      return;
    }
    if (file[0] == undefined || file[0] == "") {
      toast.error(" Category Image  is required");
      return;
    }

    const fromData = new FormData();
    fromData.append("name", name);
    fromData.append("image", file[0]);
    fromData.append("description", description);
    fromData.append("heading1", heading);
    setLoading(true);
    axios
      .post(`${BASE_URL}admin/addCategory`, fromData, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "multipart/form-data",
          Version: "1.0.0",
        },
      })
      .then(function (response) {
        if (response.data.success) {

          setLoading(false);
          toast.success(response.data.message);
          setName("");
          setDescription("");
          fileInputRef.current.value = "";
          setHeading("");
          getAllCat();
        } else {
          setLoading(false);
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
        setLoading(false);
      });
  };

  const getAllCat = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getCategory/${currentPage}`, {
        headers: {
          "x-access-token": `${token}`,
          version: "1.0.0",
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setGetAllCategory(response.data.data);
          setPages(response.data.remainingCount);
        } else {
          if(response.data.error_code == 461) {
            navigate("/SignIn");
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteCategory = (id) => {
    const token = localStorage.getItem("token");
    if (!(window.confirm("Do you really want to delete this item?"))) {
      return;
    } 
    axios
      .delete(`${BASE_URL}admin/deleteCategory/${id}`, {
        headers: {
          "x-access-token": `${token}`,
          version: "1.0.0",
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          toast.error(response.data.message);
          getAllCat();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };



  const handlePageChange = (page) => {
    setCurrentPage(page);
    getAllCat();
  };

  useEffect(() => {
    getAllCat();
  }, [currentPage]);
  return (
    <>
      <NavBar />

      <Container fluid style={{ height: "100vh", backgroundColor: "#f8f9fe;" }}>
        <Container>
          <Row className="mb-5">
            <Col md={1}></Col>
            <Col>
              <div className="p-5 pt-3 mt-5 form">
                <div
                  className="dashboard mb-2 text-center"
                  style={{ fontSize: "25px" }}
                >
                  Add Categories
                </div>
              
                <label className="pb-1 pt-2 "> 
                Category name<input
                  type="text"
                  className="text form-control mb-3"
                  placeholder="Enter Category name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                /></label>
                <label className="pb-1 pt-2 ">
                  Heading :<input
                  type="text"
                  className="text form-control mb-3"
                  placeholder="Enter heading"
                  name="heading"
                  value={heading}
                  onChange={(e) => setHeading(e.target.value)}
                /></label>
                <label className="pb-1 pt-2 ">
                  description : <input
                  type="text"
                  className="text form-control mb-3"
                  placeholder="Enter description"
                  name="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                /></label>
                <label className="pb-1 pt-2 ">
                  Image <input
                  type="file"
                  className="text form-control file"
                  name="file"
                  ref={fileInputRef}
                  onChange={(e) => setFile(e.target.files)}
                ></input>
                </label>
                <label className="pb-1 pt-3 ">
                  Status<select
                  name="status"
                  className="text form-control mb-3 "
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
                </label>
                <button
                  type="button"
                  className="btn btn-primary mt-3"
                  onClick={() => Save()}
                >
                  Save{" "}
                  {
                    loading&&<Spinner animation="border" size="sm" />
                  }
                </button>
              </div>
            </Col>
            <Col md={1}></Col>
          </Row>
          <span style={{ fontSize: "20px", fontWeight: "600" }} className="p-2">
            Category Listing
          </span>
          <div className="scrollit">
            <hr
              style={{ width: "165px", fontWeight: "700", color: "grey" }}
            ></hr>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>S no</th>
                  <th>Image</th>
                  <th> Category</th>
                  <th> heading</th>
                  <th> description</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {getAllCategory.map((item, index) => (
                  <tr key={item._id}>
                    <td className="tabletd">{++index}</td>
                    <td>
                      <Image src={item.icon} style={{ width: "40px" }}></Image>
                    </td>
                    <td>{item.name}</td>
                    <td>{item.heading1}</td>
                    <td>{item.description}</td>

                    <td>{item.status}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-primary btn-sm "
                        onClick={() =>
                          navigate("/edit_category", { state: { item } })
                        }
                      >
                        <i className="fa fa-edit"></i>{" "}
                      </button>
                      &nbsp; &nbsp;
                      <button
                        type="button"
                        className="btn btn-danger btn-sm "
                        onClick={() => deleteCategory(item._id)}
                      >
                        <i className="fa fa-trash"></i>{" "}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>

              {/* pagination  */}
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li
                    className={`page-item ${currentPage === 1 ? "disabled" : ""} `}
                  >
                    <button
                      className="page-link"
                      
                      aria-label="Previous"
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </button>
                  </li>

                  {Array.from({ length: pages }, (_, i) => i + 1).map(
                    (page) => (
                    
                        <li
                          key={page}
                          className={`page-item ${
                            page === currentPage ? "active" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => handlePageChange(page)}
                          >
                            {page}
                          </button>
                        </li>
                     
                    )
                  )}
                  <li
                    className={`page-item ${
                      currentPage === pages ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      aria-label="Next"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </button>
                  </li>
                </ul>
              </nav>
              {/* pagination end  */}
            </Table>
          </div>
        </Container>
      </Container>
    </>
  );
};
export default Category;
