import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
const NavBar = () => {
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token == null) {
      navigate("/SignIn");
    }
  }, []);

  const Logout = () => {
    localStorage.removeItem("token");
    navigate("/SignIn");
  };

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [webInfo, setWebInfo] = useState([]);
  const [id, setId] = useState("");
  const navigate = useNavigate();

  const getwebsiteinfo = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getWebsiteInfo`, {
        headers: {
          "x-access-token": `${token}`,
          version: "1.0.0",
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          if (response.data.error_code == 461) {
            navigate("/SignIn");
          }
        } else {
          setWebInfo(response.data.data);
          console.log("websiteinfonavigation", response.data.data);
          if (response.data.data._id) {
            setId(response.data.data._id);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getwebsiteinfo();
  }, []);

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand to="/">
          <Image src={webInfo.logo} fluid style={{ width: "100px" }} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">Dashboard</Nav.Link>

            <NavDropdown title="Master" id="collasible-nav-dropdown">
              <NavDropdown.Item as={Link} to="/Category">
                Category
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/SubCategory">
                SubCategory
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/ChildCategory">
                Child Category
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/HomeCategory">
                Home Category
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/ProductListing">
                Products
              </NavDropdown.Item>

              <NavDropdown.Item as={Link} to="/testimonial">
                Testimonial
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/add-size">
                Size
              </NavDropdown.Item>

              <NavDropdown.Item as={Link} to="/WebsiteInfo">
                Website Info
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Slider">
                Slider
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Banner">
                Home Banner
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/staticpageList">
                Static Pages
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/shippingcharges">
                Shipping charges
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Manage Order" id="collasible-nav-dropdown">
              <NavDropdown.Item as={Link} to="/AllOrder">
                All Order
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/new-order">
                New order
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/process">
                Process
              </NavDropdown.Item>

              <NavDropdown.Item as={Link} to="/shipped">
                Shipped
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/delivered">
                Delivered
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/failed">
                Cancelled
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/return-orders">
                Returned
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/replace-orders">
                Replace
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="User" id="collasible-nav-dropdown">
              <NavDropdown.Item as={Link} to="/alluserlist">
                All User
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav>
            <Image
              src="user-pic.jpg"
              fluid
              style={{ width: "40px", borderRadius: "50%" }}
            />
            ;
            <NavDropdown title="User Profile" id="collasible-nav-dropdown">
              <NavDropdown.Item onClick={() => navigate("/change-password")}>
                Change Password
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => Logout()}>
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default NavBar;
