import Table from "react-bootstrap/Table";
import NavBar from "../../../NavBar";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Spinner } from "react-bootstrap";

const AddProduct = () => {
  const [size, setSize] = useState("");
  const [vprice, setVprice] = useState();
  const [weight, setWeight] = useState("");
  const [fields, setFields] = useState([]);
  const [productVariant, setProductVariant] = useState([]);
  const [verityDef, setVerityDef] = useState(false);
  const [name, setName] = useState("");
  const [quantity, setQuantity] = useState();
  const [price, setPrice] = useState();
  const [shortdes, setShortdes] = useState("");
  const [longdes, setLongdes] = useState("");
  const [file, setFile] = useState([]);
  const [newarrivals, setNewarrivals] = useState(false);
  const [bestsellcheck, setBestsellcheck] = useState(false);
  const [status, setStatus] = useState("Active");
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [hcategory, setHcategory] = useState("");
  const [ctgryId, setCtgryId] = useState("");
  const [subCategoryId, setSubCategoryId] = useState("");

  const [scategory, setScategory] = useState("");

  const [category, setCategory] = useState([]);
  const [allSubcategory, setAllSubcategory] = useState([]);
  const [allChildCategory, setAllChildCategory] = useState([]);
  const [childCategoryId, setChildCategoryId] = useState([]);

  const [homeCategory, setHomeCategory] = useState([]);
  const [sizes, setSizes] = useState([]);

  const getSubCategory = () => {
    const token = localStorage.getItem("token");
    if (ctgryId) {
      axios
        .post(
          `${BASE_URL}getSubCategoryByCategoryId/${ctgryId}`,
          {},
          {
            headers: {
              "x-access-token": `${token}`,
              "Content-Type": "Application/json",
              version: "1.0.0",
            },
          }
        )
        .then(function (response) {
          if (response.data.success) {
            setAllSubcategory(response.data.data);
          } else {
            setAllSubcategory([]);
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };

  const getChildcat = () => {
    const token = localStorage.getItem("token");
    if (scategory) {
      console.log("in");
      axios
        .get(`${BASE_URL}admin/childcategorybysubcategory/${scategory}`, {
          headers: {
            "x-access-token": `${token}`,
            version: "1.0.0",
            "Content-Type": "Application/json",
          },
        })
        .then((response) => {
          if (response.data.success == false) {
            console.log(response.data.message);
            setAllChildCategory("");
          } else {
            setAllChildCategory(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const AddProduct = () => {
    if (name == "" || name.length == 0) {
      toast.error("Product name is required");
      return;
    }
    if (isNaN(price) || price == undefined) {
      toast.error("Please enter valid price. ");
      return;
    }
    if (isNaN(quantity) || quantity == undefined) {
      toast.error("Please enter valid quantity");
      return;
    }
    const token = localStorage.getItem("token");
    const fromData = new FormData();
    fromData.append("name", name);
    fromData.append("price", price);
    fromData.append("quantity", quantity);
    fromData.append("short_decription", shortdes);
    fromData.append("long_decription", longdes);
    for (const singleFile of file) {
      fromData.append("image", singleFile);
    }
    fromData.append("status", status);
    fromData.append("CategoryId", ctgryId);
    fromData.append("SubCategoryId", scategory);
    fromData.append("ChildCategoryId", childCategoryId);
    fromData.append("HomeCategoryId", hcategory);
    fromData.append("bestseller", bestsellcheck);
    fromData.append("newarrivals", newarrivals);
    setLoading(true);
    axios
      .post(`${BASE_URL}admin/addProduct`, fromData, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "multipart/form-data",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
          fileInputRef.current.value = "";
          console.log("productvariant", productVariant);
          const productVariantsWithProductId = productVariant.map(
            (variant) => ({
              ...variant,
              product_id: response.data.data._id,
            })
          );
          AddProductVarients(productVariantsWithProductId);

          toast.success(response.data.message);
          navigate("/ProductListing");
        } else {
          setLoading(false);
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.message);
      });
  };

  useEffect(() => {
    getSubCategory();
  }, [ctgryId]);

  const Fntosetsubc = (value, getSubCategory) => {
    setCtgryId(value);
    if (getSubCategory && typeof getSubCategory === "function") {
      getSubCategory();
    }
  };
  const addproductV = async () => {
    if (size == "" || size == undefined) {
      toast.error(" Size is required.");
      return;
    }
    let obj = {
      size: size,
      weightnprice: fields,
      set_default: verityDef,
    };
    productVariant.push(obj);
    setSize("");
    setVprice("");
    setWeight("");
    setFields([]);
    setVerityDef(false);
  };
  const getAllHomeCategory = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getAllHomeCategory`, {
        headers: {
          version: "1.0.0",
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setHomeCategory(response.data.data);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getSizes = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getSize`, {
        headers: {
          "Content-Type": "Application/json",
          "x-access-token": `${token}`,
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success === false) {
          console.log(response.data.message);
        } else {
          setSizes(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllCat = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getAllCategory`, {
        headers: {
          "x-access-token": `${token}`,
          version: "1.0.0",
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setCategory(response.data.data);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const AddProductVarients = (items) => {
    const token = localStorage.getItem("token");
    console.log("items", items);
    axios
      .post(`${BASE_URL}admin/addProductVarient`, items, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success === false) {
          console.log(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const addField = () => {
    setFields([
      ...fields,
      { id: fields.length, weight: weight, price: vprice },
    ]);
    setWeight("");
    setVprice("");
  };

  const removeField = (id) => {
    const newFields = fields.filter(
      (field) => field.id !== id && field.id !== id + 1
    );
    setFields(newFields);
  };

  useEffect(() => {
    addField();
    getAllCat();
    getSizes();
    getAllHomeCategory();
  }, []);

  useEffect(() => {
    getChildcat();
  }, [scategory]);

  return (
    <>
      <NavBar />
      <div className="container ">
        <div className="row d-flex justify-content-center align-items-center mt-5">
          <div
            className="col-12 col-md-10 p-3 p-md-5"
            style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
          >
            <div className="dashboard mb-2" style={{ fontSize: "25px" }}>
              Add Product
            </div>
            <div className="row">
              <div className="col-md-6">
                <label className="p-2">
                  Category
                  <select
                    name="category"
                    className="form-control"
                    value={ctgryId}
                    onChange={(e) =>
                      Fntosetsubc(e.target.value, getSubCategory)
                    }
                  >
                    <option value="">--select--</option>
                    {category.map((item, index) => (
                      <option value={item._id} key={item._id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div className="col-md-6">
                <label className="p-2">
                  Subcategory
                  <select
                    name="subcatgeory"
                    className="form-control"
                    value={scategory}
                    onChange={(e) => setScategory(e.target.value)}
                  >
                    <option value="">--select--</option>
                    {allSubcategory.map((item, index) => (
                      <option value={item._id} key={item._id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div className="col-md-6">
                <label className="formleb"> ChildCategory</label>
                <select
                  className="form-control forminp"
                  name="childCateId"
                  value={childCategoryId}
                  onChange={(e) => setChildCategoryId(e.target.value)}
                >
                  <option value="select">--select--</option>
                  {allChildCategory.length > 0 &&
                    allChildCategory.map((item, index) => (
                      <option value={item._id} key={index}>
                        {item.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-md-6">
                <label className="p-2">
                  Home Category{" "}
                  <select
                    name="homeCategory"
                    className="form-control"
                    value={hcategory}
                    onChange={(e) => setHcategory(e.target.value)}
                  >
                    <option value="">--select--</option>
                    {homeCategory.map((item, index) => (
                      <option value={item._id} key={item._id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div className="col-md-6">
                <label className="p-2">
                  Product name
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </label>
              </div>
              <div className="col-md-6">
                <label className="p-2">
                  Price
                  <input
                    type="text"
                    className="form-control"
                    name="price"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </label>
              </div>
              <div className="col-md-6">
                <label className="p-2">
                  Quantity
                  <input
                    type="text"
                    className="form-control"
                    name="quantity"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                  />
                </label>
              </div>
              <div className="col-md-6">
                <label className="p-2">
                  Image
                  <input
                    type="file"
                    className="form-control"
                    multiple
                    name="file"
                    ref={fileInputRef}
                    onChange={(e) => setFile(e.target.files)}
                  />
                </label>
              </div>
              <div className="col-md-6">
                <label className="p-2">Short description</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={shortdes}
                  onReady={(editor) => {}}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setShortdes(data);
                  }}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                />
              </div>
              <div className="col-md-6">
                <label className="p-2">Long description</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={longdes}
                  onReady={(editor) => {}}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setLongdes(data);
                  }}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                />
              </div>

              <div className="col-md-6">
                <label className="p-2">
                  Status
                  <select
                    name="status"
                    className="form-control"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value="">--select--</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </select>
                </label>
              </div>
              <div className="col-md-6 p-3">
                <label className="formleb">
                  Check mark if you want to add this product in
                  <br />
                  <div className="d-flex align-items-center">
                    <input
                      type="checkbox"
                      className="largerCheckbox"
                      onClick={(e) => setBestsellcheck(e.target.checked)}
                      checked={bestsellcheck}
                    />
                    &nbsp;&nbsp;&nbsp; <span>Bestseller</span>&nbsp;&nbsp;&nbsp;
                    <input
                      type="checkbox"
                      className="largerCheckbox"
                      onClick={(e) => setNewarrivals(e.target.checked)}
                      checked={newarrivals}
                    />
                    &nbsp;&nbsp;&nbsp; <span>New Arrivals</span>
                  </div>
                </label>
              </div>

              <div className="col-md-12">
                <div className="">
                  <div>
                    <div className="row">
                      <div className="col-md-6 p-3">
                        <label>
                          Size{" "}
                          <select
                            className="form-control"
                            onChange={(e) => setSize(e.target.value)}
                          >
                            <option className="" value="">
                              --select--
                            </option>
                            {sizes?.map((item, index) => (
                              <option
                                className=""
                                key={index}
                                value={item.name}
                              >
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </label>
                      </div>
                      <div>
                        <button className="btn btn-primary" onClick={addField}>
                          <span className="fa fa-plus">add</span>
                        </button>
                        <div id="extend-field">
                          {fields.map((field) => (
                            <div className="row" key={field.id}>
                              <div className="col ">
                                <label>
                                  Weight <br />
                                  <input
                                    type="text"
                                    value={field.weight}
                                    className="form-control"
                                    onChange={(e) =>
                                      setFields(
                                        fields.map((f) =>
                                          f.id === field.id
                                            ? { ...f, weight: e.target.value }
                                            : f
                                        )
                                      )
                                    }
                                  />
                                </label>
                              </div>
                              <div className="col ">
                                <label>
                                  Price
                                  <br />
                                  <input
                                    type="text"
                                    value={field.price}
                                    className="form-control"
                                    onChange={(e) =>
                                      setFields(
                                        fields.map((f) =>
                                          f.id === field.id
                                            ? { ...f, price: e.target.value }
                                            : f
                                        )
                                      )
                                    }
                                  />
                                </label>
                              </div>
                              <div className="col-2">
                                <br />
                                <button onClick={() => removeField(field.id)}>
                                  Remove
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="col-md-6 p-3">
                        <label>Check if you wanna set it default </label>
                        <br />
                        <input
                          className="largerCheckbox"
                          type="checkbox"
                          placeholder="set to default"
                          onClick={(e) => setVerityDef(e.target.checked)}
                          checked={verityDef}
                        />
                      </div>
                      <div className="col-md-6 p-3"></div>
                      <br />
                    </div>
                    <button
                      className="btn btn-primary"
                      onClick={() => addproductV()}
                    >
                      {" "}
                      add option
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-12 d-none">
                <Table striped bordered hover>
                  {productVariant.length > 0 ? (
                    <>
                      {" "}
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Size</th>
                          <th>Weight</th>
                          <th>Price</th>
                          <th>default</th>
                        </tr>
                      </thead>
                      <tbody>
                        {productVariant.length > 0
                          ? productVariant.map((item, index) => (
                              <tr key={item._id}>
                                <td>{index + 1}</td>
                                <td>{item.size}</td>
                                <td>
                                  {item.weightnprice.length > 0 &&
                                    item.weightnprice.map((item1, index1) => (
                                      <div key={index1}>{item1.weight}</div>
                                    ))}
                                </td>
                                <td>
                                  {item.weightnprice.length > 0 &&
                                    item.weightnprice.map((item1, index1) => (
                                      <div key={index1}>{item1.price}</div>
                                    ))}
                                </td>
                                <td>{item.set_default ? "True" : "false"}</td>
                              </tr>
                            ))
                          : ""}
                      </tbody>
                    </>
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "200px", width: "100%" }}
                    >
                      <div>No record Added</div>
                    </div>
                  )}
                </Table>
              </div>
              <button
                type="submit"
                className="btn btn-sm btn-primary mt-3 ms-3"
                style={{ width: "60px" }}
                onClick={() => AddProduct()}
              >
                Save {loading && <Spinner animation="border" size="sm" />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddProduct;
