
import {  Spinner } from "react-bootstrap";
import NavBar from "../../../NavBar";
import Table from "react-bootstrap/Table";
import { useState,useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Size = () => {
  const [name, setName] = useState("");
  const [status, setStatus] = useState("Active");
  const [loading, setLoading] = useState(false);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [getSize, setGetSize] = useState([]);
  const navigate = useNavigate();
  const Addsize = () => {
    const token = localStorage.getItem("token");
    if (name == "" || name.length == 0) {
      toast.error("Size is required.");
      return;
    }
    const obj = {
      name: name,
      status: status,
    };
    setLoading(true);
    axios
      .post(`${BASE_URL}admin/addSize`, obj, {
        headers: {
          "x-access-token": `${token}`,
          version: "1.0.0",
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
          toast.success(response.data.message);
          setName("");
          getallSizes();
        } else {
          setLoading(false);
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const getallSizes = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getSize`, {
        headers: {
          version: "1.0.0",
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          if (response.data.error_code == 461) {
            navigate("/SignIn");
          }
        } else {
          console.log("response.data.data", response.data.data);
          setGetSize(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteSize = (id) => {
    if (!(window.confirm("Do you really want to delete this item?"))) {
      return;
    } 
    const token = localStorage.getItem("token");
    axios
      .delete(`${BASE_URL}admin/deleteSize/${id}`, {
        headers: {
          version: "1.0.0",
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          toast.error(response.data.message);
          if (response.data.error_code == 461) {
            navigate("/SignIn");
          }
        } else {
          toast.error(response.data.message);
          getallSizes();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getallSizes();
  }, []);

  return (
    <>
      <NavBar />
      <div className="container-fluid"  style={{ height: "100vh", backgroundColor: "#f8f9fe;" }}>
        <div className="container">
          <div  className="row mb-5">
            <div className="col col-md-1" ></div>
            <div className="col">
              <div className="p-5 pt-3 mt-5 form">
                <div
                  className="dashboard mb-2 text-center"
                  style={{ fontSize: "25px" }}
                >
                  Add Size
                </div>

            
                  <label className="mb-1 mt-3">
                    Size:<input
                    type="text"
                    placeholder="Enter Size:"
                    className="text form-control"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  /></label>
                

                <label className="mt-3mb-1">
                Status:<select
                  name="status"
                  className="text form-control "
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
                </label>
                <button
                  type="button"
                  className="btn btn-primary mt-3"
                  onClick={() => Addsize()}
                >
                  {" "}
                  Save{" "}{
                    loading&&<Spinner animation="boder" size="sm"/>
                  }
                </button>
              </div>
            </div>
            <div className="col col-md-1" ></div>
          </div>
          <span style={{ fontSize: "20px", fontWeight: "600" }} className="p-2">
            Size Listing
          </span>
          <div className="scrollit">
            <hr
              style={{ width: "165px", fontWeight: "700", color: "grey" }}
            ></hr>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>S no.</th>
                  <th> Size</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {getSize.map((item, index) => (
                  <tr key={item._id}>
                    <td className="tabletd">{++index}</td>

                    <td>{item.name}</td>

                    <td>{item.status}</td>
                    <td>
                      <button type="button" className="btn btn-primary btn-sm ">
                        <i
                          className="fa fa-edit"
                          onClick={() =>
                            navigate("/update-size", { state: { item } })
                          }
                        ></i>{" "}
                      </button>
                      &nbsp; &nbsp;
                      <button type="button" className="btn btn-danger btn-sm ">
                        <i
                          className="fa fa-trash"
                          onClick={() => deleteSize(item._id)}
                        ></i>{" "}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};
export default Size;
