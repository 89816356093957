
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import React, { useState , useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';

import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from 'react-router-dom';


const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
}



const SignIn = () => {
    const navigate=useNavigate();
    const [fullname, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [captcha, setCaptcha] = useState(Math.floor(100000 + Math.random() * 900000));
    const [enterCaptcha, setEnterCaptcha] = useState('');
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        const interval = setInterval(() => {
         setCaptcha(Math.floor(100000 + Math.random() * 900000));
        }, 15000);
        return () => clearInterval(interval);
      }, []);


    const Submitinfo = () => {
        console.log("hiii it has click");

        const obj = {
            email: email,
            role:"3",
            password: password,
        };

        


        
        function isValidEmail(email) {
            return /\S+@\S+\.\S+/.test(email);
        }
        if (email.length > 50) {
            toast.error("please enter valid email address length ");
        }
        else {
            if (isValidEmail(email)) {
                setEmail(email);
            }
            else {
                toast.error("please enter valid email address");
            }
        }

        if (enterCaptcha != captcha) {
            toast.error("Enter right Captcha")
            return ;
        }
        if(password.length>6)
        {
            toast.error("password must be upto 6 digit ");
        }
        else if(password.length<=3){
      toast.error("password must be of minimum 4 digit");
        }



        axios
        .post(`${BASE_URL}admin/login`,obj,
        {
            headers:{
                "version":"1.0.0",
                "Content-type":"Application/json",
            }
        }).then(function(response){
            if(response.data.success==false){
                toast.error(response.data.message);
            }
            else{
                localStorage.setItem("User", response.data.data)
                toast.success(response.data.message);
                localStorage.setItem("token",response.data.data.token)

                navigate("/");

            }
          
        }
        )
        .catch(function(error){
               console.log(error); 
        })
       
    }

 
    return (
        <>
        <div className='container-fluid box p-0 m-0'
        style={{height:"100%"}}
        >
        <div className='' style={{height:"135vh"}}>
            <Container fluid className="">
                
                <Container className='pt-5'>
                    <div className='mt-5'>
                        <Row className=''>
                            <Col lg={2}>

                            </Col>
                            <Col className='backgroundimgcol mb-5'>
                                <div className='p-5 text-center' >
                                    <h1 style={{ fontFamily: 'Brush Script MT,Palatino Linotype' }}>Sign in</h1>
                                    <p style={{ fontFamily: 'Palatino Linotype' }}>
                                        <b> orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum</b>
                                    </p>

                                </div>
                            </Col>
                            <Col className='form mb-5'>
                                <Form>
                                    <Form.Group className="mb-3 " controlId="formBasicEmail" >
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control type="email" required placeholder="Enter email" style={{ borderRadius: 0 }} className='p-2'
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)
                                            } />

                                    </Form.Group>

                                    <Form.Group className="mb-3 " controlId="formBasicPassword" >
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" required placeholder="Password" style={{ borderRadius: 0 }} className='p-2'
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)

                                            }
                                        />
                                    </Form.Group>
                                    <Row>
                                        <Col>
                                        <Form.Group className="mb-3 " controlId="formBasicCaptcha" >
                                        <Form.Label>Captcha</Form.Label>
                                        <Form.Control type="number" readOnly placeholder="" style={{ borderRadius: 0 }} className='p-2 captcha'
                                            value={captcha}
                                        />
                                    </Form.Group>
                                        </Col>
                                        <Col>
                                        
                                    <Form.Group className="mb-3 " controlId="formBasicCatcha" >
                                        <Form.Label>Enter Captcha</Form.Label>
                                        <Form.Control type="number" placeholder="Enter Captcha" style={{ borderRadius: 0 }} className='p-2'
                                            value={enterCaptcha}
                                            onChange={(e) => setEnterCaptcha(e.target.value)
                                            }
                                        />
                                    </Form.Group>
                                        </Col>
                                    </Row>

                                    <Form.Group className="mb-3 " controlId="formBasicCheckbox">
                                        <Form.Check type="checkbox" label="Remember me " style={{ borderRadius: 0 }} className='p-2' />
                                    </Form.Group>
                                    <Button variant="success" type="button" style={{ width: '100%', borderRadius: 0 }} className='p-2' onClick={Submitinfo}>
                                        Sign in
                                    </Button>
                                </Form>
                            </Col>
                            <Col className='' lg={2}>

                            </Col>
                        </Row>
                    </div>

                </Container>
            </Container>
            </div>
            </div>
        </>
    );
}
export default SignIn;