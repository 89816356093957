import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';

function PrintInvoice() {
    const { state } = useLocation();
    const [webInfo, setWebInfo] = useState([]);
    const navigate = useNavigate();
    const BASE_URL = process.env.REACT_APP_BASE_URL;



    const getwebsiteinfo = () => {
        const token = localStorage.getItem("token");
        axios
            .get(`${BASE_URL}admin/getWebsiteInfo`, {
                headers: {
                    "x-access-token": `${token}`,
                    "version": "1.0.0",
                    "Content-Type": "Application/json"
                }
            }).then((response) => {
                if (response.data.success == false) {
                    if (response.data.error_code == 461) {
                        navigate("/SignIn");
                    }

                } else {
                    setWebInfo(response.data.data);
                    console.log("websiteinfonavigation", response.data.data);


                }
            }).catch((error) => {
                console.log(error);
            })

    }

    useEffect(() => {
        getwebsiteinfo();
        console.log("state", state);
    }, [])
    return (
     
            <div>
                <div className="w-100">
                    <div className="row expanded">
                        <main className="columns">
                            <div className="inner-container">
                              
                                <section className="row aaaa printme">
                                    <div className="callout large invoice-container">
                                        <table className="invoice w-100">
                                            <tbody><tr className="header">
                                                <td className>
                                                    <img src={webInfo.logo} width={100} height={100} alt="Company Name" />
                                                </td>
                                                <td className="align-right">
                                                    <h2>Invoice</h2>
                                                </td>
                                            </tr>
                                                <tr className="intro">
                                                    <td className style={{ width:"65%" }}>
                                                        <h3>Bill TO</h3>
                                                        <p className='mb-1 w-50'>Address:{webInfo.address}</p>
                                                        <p className='mb-0'>Contact No.:{webInfo.phone_no}</p>
                                                        <p className='mb-0'>Email Id:{webInfo.email}</p>

                                                        Thank you for your order.

                                                    </td>
                                                    <td className="text-right">
                                                    <h3  style={{textAlign:"start"}}>Ship TO</h3>
                                                    <p className='mb-1' style={{textAlign:"start"}}>Name:  &nbsp;&nbsp;{state.name}</p>

                                                    <p className='mb-1' style={{textAlign:"start"}}>Address1:&nbsp;&nbsp;{state.address}</p>
                                                    <p className='mb-1 ' style={{textAlign:"start"}}>Address2:&nbsp;&nbsp;{state.address2}</p>


                                                        <p className="num" style={{textAlign:"start"}}>Order Id: &nbsp;&nbsp;#{state.user_Order_ID}</p>
                                                      <p style={{textAlign:"start"}}>&nbsp;&nbsp;{moment(state.createdAt).format('MM/DD/YYYY')}</p>  
                                                    </td>
                                                </tr>
                                                <tr className="details" style={{ width:"65%" }}>
                                                    <td colSpan={2}>
                                                        <table style={{ width: "100%" }}>
                                                            <thead>
                                                                <tr>
                                                                    <th className="desc">Item Name</th>
                                                                    <th className="id">Size</th>
                                                                    <th className="id">Weight</th>
                                                                    <th className="id">Product Id</th>
                                                                    <th className="qty">Quantity</th>
                                                                    <th className="amt">Subtotal</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                               
                                                                {
                                                                    state.Order_details?.length > 0 && state.Order_details.map((order, index) => (
                                                                        order.Products_details.map((product, idx) => (
                                                                          
                                                                                <tr className="item" key={`${index}-${idx}`}>
                                                                                    <td className="desc">{product.name}</td>
                                                                                    <td className="desc">{order.size}</td>
                                                                                    <td className="desc">{order.weight}</td>
                                                                                    <td className="id num">{product.productId}</td>
                                                                                    <td className="qty">{order.quantity}</td>
                                                                                    <td className="amt">{order.price}</td>
                                                                                </tr>
                                                                         


                                                                        ))

                                                                    ))
                                                                }


                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr className="totals">
                                                    <td />
                                                    <td>
                                                        <table style={{ width: "100%" }}>
                                                            <tbody><tr className="subtotal">
                                                                <td className="num">Subtotal</td>
                                                                <td className="num">{state.amount}</td>
                                                            </tr>
                                                                <tr className="fees">
                                                                    <td className="num">Shipping &amp; Charges</td>
                                                                    <td className="num">{state.shipping_charge}</td>
                                                                </tr>

                                                                <tr className="total">
                                                                    <td>Total</td>
                                                                    <td>{state.total_amount}</td>
                                                                </tr>
                                                            </tbody></table>
                                                    </td>
                                                </tr>
                                            </tbody></table>
                                            <hr/>
                                        <section className="additional-info">
                                            <div className="row">
                                              
                                                <div className="columns">
                                                    <h5>Payment Information</h5>
                                                    <p>Payment Method:&nbsp;&nbsp;
                                                        {state.delivery_type}
                                                    </p>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </section>

                                <header className="row align-center">
                                   
                                    &nbsp;&nbsp;<button onClick={()=>window.print()} className="button p-2"><i className="ion ion-ios-printer-outline" /> Print Invoice</button>
                                </header>
                            </div>
                        </main>
                    </div>
                </div>
                <div>
                </div></div>


     
    )
}

export default PrintInvoice