import { Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import NavBar from "../../../NavBar";
import { useNavigate } from "react-router-dom";
const Childcategory = () => {
  const [name, setName] = useState("");
  const [status, setStatus] = useState("Active");
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [token, setToken] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [getallcategory, setGetAllCategory] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [subcategoryId, setSubCategoryId] = useState("");
  const [allChildCategory, setAllChildCategory] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    getAllCat();
    let token = localStorage.getItem("token");
    if (token) {
      setToken(token);
    } else {
      navigate("/SignIn");
    }
  }, [token]);

  const Save = async () => {
    if (name.length == 0 && name == "") {
      toast.error("Child category name is required");
      return;
    }

    const fromData = new FormData();
    fromData.append("name", name);
    fromData.append("CategoryId", categoryId);
    fromData.append("SubCategoryId", subcategoryId);
    fromData.append("status", status);
    const obj = {
      name: name,
      CategoryId: categoryId,
      SubCategoryId: subcategoryId,
      status: status,
    };
    axios
      .post(`${BASE_URL}admin/addChildSubCategory`, obj, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          Version: "1.0.0",
        },
      })
      .then(function (response) {
        if (response.data.success == false) {
          toast.error(response.data.message);
        } else {
          toast.success(response.data.message);
          getAllChildcategory();
          setName("");
          getAllCat();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const getSubCategory = () => {
    if (categoryId) {
      axios
        .post(
          `${BASE_URL}admin/getSubCategoryByCategoryId/${categoryId}`,
          {},
          {
            headers: {
              "x-access-token": `${token}`,
              "Content-Type": "Application/json",
              version: "1.0.0",
            },
          }
        )
        .then(function (response) {
          if (response.data.success == false) {
            setSubCategories("");
          } else {
            setSubCategories(response.data.data);
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };

  const getAllCat = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getAllCategory`, {
        headers: {
          "x-access-token": `${token}`,
          version: "1.0.0",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          if (response.data.error_code == 461) {
            navigate("/SignIn");
          }
        } else {
          setGetAllCategory(response.data.data);
          console.log("category====>", response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const deletedChildcategory = (id) => {
    if (window.confirm("Do you really want to delete this item?")) {
    } else {
      return;
    }
    const token = localStorage.getItem("token");
    axios
      .put(
        `${BASE_URL}admin/deleteChildSubCategory/${id}`,
        {},
        {
          headers: {
            "x-access-token": `${token}`,
            version: "1.0.0",
            "Content-Type": "Application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success == false) {
          toast.error(response.data.message);
        } else {
          toast.error(response.data.message);
          getAllChildcategory();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllChildcategory = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getAllChildSubCategory`, {
        headers: {
          "Content-Type": "Application/json",
          version: "1.0.0",
          "x-access-token": `${token}`,
        },
      })
      .then((response) => {
        if (response.data.success) {
          setAllChildCategory(response.data.data);
          console.log(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const Fntosetsubc = (value, getSubCategory) => {
    console.log("value", value);
    setCategoryId(value);
    if (getSubCategory && typeof getSubCategory === "function") {
      getSubCategory();
    }
  };
  useEffect(() => {
    getAllChildcategory();
  }, []);

  useEffect(() => {
    getSubCategory();
  }, [categoryId]);
  return (
    <>
      <NavBar />
      <Container fluid style={{ height: "100vh", backgroundColor: "#f8f9fe;" }}>
        <Container>
          <Row className="mb-5">
            <Col md={2}></Col>
            <Col className="p-3">
              <Row style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                <div className="p-5 mt-3 form">
                  <div
                    className="dashboard mb-2 heading"
                    style={{ fontSize: "25px" }}
                  >
                    Child Category
                  </div>
                  <br />
                  <label className="py-2"> Category</label>
                  <Form.Select
                    aria-label="Default select example"
                    className="mb-3 select forminp"
                    name="categoryId"
                    value={categoryId}
                    onChange={(e) =>
                      Fntosetsubc(e.target.value, getSubCategory)
                    }
                  >
                    <option> --Select Category --</option>
                    {getallcategory.map((item, index) => (
                      <option value={item._id} key={index}>
                        {item.name}
                      </option>
                    ))}
                  </Form.Select>
                  <label className="formleb">Subcategory</label>
                  <select
                    name="subcatgeory"
                    className="form-control forminp"
                    value={subcategoryId}
                    onChange={(e) => setSubCategoryId(e.target.value)}
                  >
                    <option value="">--Select Subcategory--</option>
                    {subCategories.length > 0 ? (
                      subCategories.map((item, index) => (
                        <option value={item._id} key={index}>
                          {item.name}
                        </option>
                      ))
                    ) : (
                      <span>no record found</span>
                    )}
                  </select>

                  <br />
                  <label className="formleb">Childcategory Name:</label>

                  <input
                    type="text"
                    className="forminp form-control mb-3"
                    placeholder="Enter Childcategory name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />

                  <label className="formleb">Select Status</label>

                  <select
                    className="forminp form-control"
                    name="status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option selected>--Select status--</option>

                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                    <option></option>
                  </select>
                  <button
                    type="button"
                    className="formbtn btn btn-primary mt-3"
                    onClick={() => Save()}
                  >
                    {" "}
                    Save{" "}
                  </button>
                </div>
              </Row>
            </Col>
            <Col md={2}></Col>
          </Row>
          <Row>
            <Col className="col-md-1"></Col>
            <Col>
              <div className="scrollit">
                <span
                  style={{ fontSize: "20px", fontWeight: "600" }}
                  className="p-2 heading"
                >
                  Child category Listing
                </span>
                <div className="" style={{ overflow: "auto" }}>
                  <Table striped bordered hover className="mt-3">
                    <thead>
                      <tr>
                        {/* <th>#</th> */}
                        <th>Category</th>
                        <th>Sub category</th>
                        <th>Name</th>

                        <th>status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allChildCategory.length > 0 &&
                        allChildCategory.map((item, index) => (
                          <tr key={index}>
                            <td>{item.category_details[0].name}</td>
                            <td>{item.SubCategory_details[0].name}</td>
                            <td>{item.name}</td>
                            <td>{item.status}</td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-primary "
                                onClick={() =>
                                  navigate("/Edit-ChildCategory", {
                                    state: { item },
                                  })
                                }
                              >
                                <i className="fa fa-edit"></i>{" "}
                              </button>
                              &nbsp; &nbsp;
                              <button
                                type="button"
                                className="btn btn-danger "
                                onClick={() => deletedChildcategory(item._id)}
                              >
                                <i className="fa fa-trash"></i>{" "}
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  {allChildCategory.length > 0 ? (
                    ""
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center "
                      style={{ height: "300px" }}
                    >
                      <div>No child category available</div>
                    </div>
                  )}
                </div>
              </div>
            </Col>
            <Col className="col-md-1"></Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};
export default Childcategory;
