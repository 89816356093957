import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./App.css";
import NavBar from "./NavBar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const Dashboardd = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [webInfo, setWebInfo] = useState([]);
  const [id, setId] = useState("");
  const [counts, setCounts] = useState({});
  const navigate = useNavigate();

  const getwebsiteinfo = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getWebsiteInfo`, {
        headers: {
          "x-access-token": `${token}`,
          version: "1.0.0",
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          if (response.data.error_code == 461) {
            navigate("/SignIn");
          }
        } else {
          setWebInfo(response.data.data);
          console.log("websiteinfo", response.data.data);
          if (response.data.data._id) {
            setId(response.data.data._id);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const DashboardCount = () => {
    let token = localStorage.getItem("token");
    axios
      .post(
        `${BASE_URL}auth/dashboardCount`,
        {},
        {
          headers: {
            "x-access-token": `${token}`,
            "Content-type": "Application/json",
            version: "1.0.0",
          },
        }
      )
      .then((response) => {
        setCounts(response.data.data);
        console.log("counts", response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    DashboardCount();
    getwebsiteinfo();
  }, []);
  return (
    <>
      <NavBar />
      <Container fluid className="content">
        <Container className="mb-3 w-100">
          <div className="Count-sec">
            <p className="pt-3" style={{ color: "grey", fontSize: "25px" }}>
              Dashbaord
            </p>
            <div className="mt-3 p-2">
              <Row className=" w-100">
                <Col className="p-3" lg={3} md={4} xs={6} sm={4}>
                  <div className="taskdiv">
                    <Row className="">
                      <Col className="p-4 text-center" md={3}>
                        <span className="p-4 mt-3 ">
                          {" "}
                          <i
                            className="		fa fa-cart-plus mt-3"
                            style={{ fontSize: "45px" }}
                          ></i>
                        </span>
                      </Col>
                      <Col className="p-3 text-center">
                        <p>Pending Order</p>
                        <h4>{counts.PendingOrderCount}</h4>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col className="p-3" lg={3} md={4} xs={6} sm={4}>
                  <div className="taskdiv1">
                    <Row className="">
                      <Col className="p-4 text-center" md={3}>
                        <span className="p-4 mt-3 ">
                          {" "}
                          <i
                            className="		fa fa-user-o mt-3"
                            style={{ fontSize: "45px" }}
                          ></i>
                        </span>
                      </Col>
                      <Col className="p-3 text-center">
                        <p>Total User</p>
                        <h4>{counts.UserCount}</h4>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col className="p-3" lg={3} md={4} xs={6} sm={4}>
                  <div
                    className="taskdiv2"
                    style={{ backgroundColor: "green" }}
                  >
                    <Row className="">
                      <Col className="p-4 text-center" md={3}>
                        <span className="p-4 mt-3 ">
                          {" "}
                          <i
                            className="		fa fa-cart-plus mt-3"
                            style={{ fontSize: "45px" }}
                          ></i>
                        </span>
                      </Col>
                      <Col className="p-3 text-center">
                        <p>Success order</p>
                        <h4>{counts.SuccessOrderCount}</h4>
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col className="p-3" lg={3} md={4} xs={6} sm={4}>
                  <div className="taskdiv2">
                    <Row className="">
                      <Col className="p-4 text-center" md={3}>
                        <span className="p-4 mt-3 ">
                          {" "}
                          <i
                            className="		fa fa-cart-plus mt-3"
                            style={{ fontSize: "45px" }}
                          ></i>
                        </span>
                      </Col>
                      <Col className="p-3 text-center">
                        <p>Cancelled order</p>
                        <h4>{counts.cancelledOrderCount}</h4>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </Container>
    </>
  );
};
export default Dashboardd;
