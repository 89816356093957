import axios from "axios";
import { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { toast } from "react-toastify";
import NavBar from "../../NavBar";
import { useNavigate } from "react-router-dom";
const WebsiteInfo = () => {
  const navigate = useNavigate();
  const initialstate = {
    phone_no: "",
    email: "",
    address: "",
    about_us: "",
    announcement: "",
    title: "",
    description: "",
    keyword: "",
    facebook_url: "",
    instgram_url: "",
    youtube_url: "",
    twitter_url: "",
    google_url: "",
    // logo:""
  };
  const [file, setFile] = useState(undefined);

  const [favicon_icon, setFavicon_icon] = useState(undefined);
  const [value, setValue] = useState(initialstate);

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [id, setId] = useState("");
  // const [webinfo, setWebinfo]=useState([]);
  const token = localStorage.getItem("token");

  const getwebsiteinfo = () => {
    axios
      .get(`${BASE_URL}admin/getWebsiteInfo`, {
        headers: {
          "x-access-token": `${token}`,
          version: "1.0.0",
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          if (response.data.error_code == 461) {
            navigate("/SignIn");
          }
        } else {
          setValue(response.data.data);
          console.log("websiteinfo", response.data.data);
          if (response.data.data._id) {
            setId(response.data.data._id);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getwebsiteinfo();
  }, []);

  const handleChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const addupwebinfo = () => {
    const formData = new FormData();
    formData.append("phone_no", value.phone_no);
    formData.append("email", value.email);
    formData.append("address", value.address);
    formData.append("about_us", value.about_us);
    if (file !== undefined) {
      formData.append("logo", file[0]);
    }
    if (favicon_icon !== undefined) {
      formData.append("favicon_icon", favicon_icon[0]);
    }
    if (id) {
      formData.append("id", id);
    }

    formData.append("announcement", value.announcement);
    formData.append("title", value.title);
    formData.append("description", value.description);
    formData.append("keyword", value.keyword);
    formData.append("facebook_url", value.facebook_url);
    formData.append("instgram_url", value.instgram_url);
    formData.append("youtube_url", value.youtube_url);
    formData.append("twitter_url", value.twitter_url);
    formData.append("google_url", value.google_url);
    axios
      .post(`${BASE_URL}admin/addUpdateWebsiteInfo`, formData, {
        headers: {
          "Content-Type": "Application/json",
          "x-access-token": `${token}`,
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          if (response.data.error_code == 461) {
            navigate("/SignIn");
          }
          toast.error(response.data.message);
        } else {
          toast.success(response.data.message);
          getwebsiteinfo();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <NavBar />
      <div className="side  container ">
        <div className="row d-flex justify-content-center align-items-center mt-5">
          <div
            className="col-10 p-5"
            style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
          >
            <Accordion defaultActiveKey="0" flus>
              <Accordion.Item eventKey="0">
                <Accordion.Header>website Info</Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-6">
                      <label>
                        Mobile no<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="phone_no"
                        value={value.phone_no}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-md-6">
                      <label>
                        Email<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        value={value.email}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-md-6">
                      <label>
                        Address<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="address"
                        value={value.address}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <label>
                        About us<span style={{ color: "red" }}>*</span>
                      </label>

                      <input
                        type="text"
                        className="form-control"
                        name="about_us"
                        value={value.about_us}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <label>
                        logo<span style={{ color: "red" }}>*</span>
                      </label>

                      <input
                        type="file"
                        name="file"
                        className="form-control"
                        onChange={(e) => setFile(e.target.files)}
                      />
                      <img
                        src={value.logo}
                        style={{ width: "100px", height: "80px" }}
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Announcement bar</Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-12">
                      <label>
                        Announcement bar<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="announcement"
                        value={value.announcement}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>SEO</Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-6">
                      <label>
                        Meta title<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        value={value.title}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-md-6">
                      <label>
                        Meta keyword<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="keyword"
                        value={value.keyword}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-md-6">
                      <label>
                        Meta Description*<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="description"
                        value={value.description}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <label>
                        Favicon Icon<span style={{ color: "red" }}>*</span>
                      </label>

                      <input
                        type="file"
                        className="form-control"
                        name="favicon_icon"
                        onChange={(e) => setFavicon_icon(e.target.files)}
                      />

                      <img
                        src={value.favicon_icon}
                        style={{ width: "100px", height: "80px" }}
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Social Media Link</Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-6">
                      <label>
                        Facebook<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="facebook_url"
                        value={value.facebook_url}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-md-6">
                      <label>
                        Instagram<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="instgram_url"
                        value={value.instgram_url}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-md-6">
                      <label>
                        Youtube<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="youtube_url"
                        value={value.youtube_url}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <label>
                        twitter<span style={{ color: "red" }}>*</span>
                      </label>

                      <input
                        type="text"
                        className="form-control"
                        name="twitter_url"
                        value={value.twitter_url}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <label>
                        Google Business<span style={{ color: "red" }}>*</span>
                      </label>

                      <input
                        type="text"
                        className="form-control"
                        name="google_url"
                        value={value.google_url}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <button
                type="sumbit"
                className="btn btn-sm btn-primary mt-3 ms-2"
                onClick={() => addupwebinfo()}
              >
                Save
              </button>
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};
export default WebsiteInfo;
