import { useEffect, useRef, useState } from "react";
import NavBar from "../../../NavBar";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

const Banner = () => {
  const [sidebanner1, setSidebanner1] = useState("");
  const [sidebanner_url1, setSidebanner_url1] = useState("");
  const fileInputRef = useRef(null);
  const [sidebanner2, setSidebanner2] = useState("");
  const [sidebanner_url2, setSidebanner_url2] = useState("");

  const [footerbanner, setFooterbanner] = useState("");
  const [footerbanner_url, setFooterbanner_url] = useState("");
  const [id, setId] = useState("");
  const [loading , setLoading]=useState(false);
  const navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;


  const getbanner = () => {
  const token = localStorage.getItem("token");

    axios
      .get(`${BASE_URL}admin/getBanner`, {
        headers: {
          "Content-Type": "Application/json",
          version: "1.0.0",
          "x-access-token": `${token}`,
        },
      })
      .then((response) => {
        if ((response.data.success = false)) {
          if (response.data.error_code == 461) {
            navigate("/SignIn");
          }
        } else {
          setSidebanner1(response.data.data.sideBanner1);
          setSidebanner_url1(response.data.data.sideBannerUrl1);
          setSidebanner2(response.data.data.sideBanner2);
          setSidebanner_url2(response.data.data.sideBannerUrl2);
          setFooterbanner(response.data.data.footerBanner);
          setFooterbanner_url(response.data.data.footerBannerUrl);
          if (response.data.data._id) {
            setId(response.data.data._id);
          }
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const addbanner = () => {
  const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("sideBanner1", sidebanner1[0]);
    formData.append("sideBannerUrl1", sidebanner_url1);
    formData.append("sideBanner2", sidebanner2[0]);
    formData.append("sideBannerUrl2", sidebanner_url2);
    formData.append("footerBanner", footerbanner[0]);
    formData.append("footerBannerUrl", footerbanner_url);
    if (id) {
      formData.append("id", id);
    }
    setLoading(true)
    axios
      .post(`${BASE_URL}admin/addBanner`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          version: "1.0.0",
          "x-access-token": `${token}`,
        },
      })
      .then((response) => {
        if (response.data.success) {
          setLoading(false)
          fileInputRef.current.value = "";
          toast.error(response.data.message);
        } else {
          setLoading(false)
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false)
        console.log(error.message);
      });
  };

  useEffect(() => {
    getbanner();
  }, []);
  return (
    <>
      <NavBar />
      <div className="container ">
        <div className="row d-flex justify-content-center align-items-center mt-5">
          <div
            className="col-10 p-5"
            style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
          >
            <div className="row">
              <div className="col-md-6">
                <label className="p-3">
                Side Banner Url 1<input
                  type="text"
                  className="form-control"
                  name="sidebanner_url1"
                  value={sidebanner_url1}
                  onChange={(e) => setSidebanner_url1(e.target.value)}
                /></label>
              </div>
              <div className="col-md-6">
                <label className="p-3">
                  Side Banner Image 1 <input
                  type="file"
                  className="form-control"
                  accept=".png , .jpeg , .jpg ,"
                  name="sidebanner1"
                  ref={fileInputRef}
                  // value={sidebanner1}

                  onChange={(e) => setSidebanner1(e.target.files)}
                /></label>
                <img
                  src={sidebanner1}
                  className="img-fluid"
                  width={100}
                  height={80}
                  alt=""
                />
              </div>

              <div className="col-md-6">
                <label className="p-3">
                 Side Banner Url 2<input
                  type="text"
                  className="form-control"
                  name="sidebanner_url2"
                  value={sidebanner_url2}
                  onChange={(e) => setSidebanner_url2(e.target.value)}
                /></label>
              </div>
              <div className="col-md-6">
                <label className="p-3">
                  Side Banner Image 2<input
                  type="file"
                  className="form-control"
                  accept=".png , .jpeg , .jpg ,"
                  name="sidebanner2"
                  ref={fileInputRef}
                  onChange={(e) => setSidebanner2(e.target.files)}
                />
</label>
                <img
                  src={sidebanner2}
                  className="img-fluid"
                  width={100}
                  height={80}
                  alt=""
                />
              </div>

              <div className="col-md-6">
                <label className="p-3">
                  Footer Banner Url 3<input
                  type="text"
                  className="form-control"
                  name="footerbanner_url"
                  value={footerbanner_url}
                  onChange={(e) => setFooterbanner_url(e.target.value)}
                /></label>
              </div>
              <div className="col-md-6">
                <label className="p-3">
                Footer Banner Image 3<input
                  type="file"
                  className="form-control"
                  accept=".png , .jpeg , .jpg ,"
                  name="footerbanner"
                  ref={fileInputRef}
                  onChange={(e) => setFooterbanner(e.target.files)}
                /></label>
                <img
                  src={footerbanner}
                  className="img-fluid"
                  width={100}
                  height={80}
                  alt=""
                />
              </div>

              <button
                type="submit"
                className="btn btn-sm btn-primary mt-3 ms-3"
                style={{ width: "60px" }}
                onClick={() => addbanner()}
              >
                Save
                {
                  loading&& <Spinner animation="border" size="sm"/>
                }
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Banner;
