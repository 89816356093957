import Table from "react-bootstrap/Table";
import NavBar from "../../../NavBar";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Spinner } from "react-bootstrap";

const UpdateProduct = () => {
  const { state } = useLocation();
  const [sizes, setSizes] = useState([]);
  const [vprice, setVprice] = useState();
  const [weight, setWeight] = useState("");
  const [fields, setFields] = useState([
    { id: 1, weight: weight, price: vprice },
  ]);
  const [size, setSize] = useState("");
  const [productvarient, setProductvarient] = useState(
    state.item.product_varients
  );
  const [verityDef, setVerityDef] = useState();
  const [productVId, setProductVId] = useState("");
  const [newProductVarients, setNewProductVarients] = useState([]);
  const [name, setName] = useState(state.item.name);
  const [quantity, setquantity] = useState(state.item.quantity);
  const [price, setPrice] = useState(state.item.price);
  const [shortdes, setShortdes] = useState(state.item.short_decription);
  const [longdes, setLongdes] = useState(state.item.long_decription);
  const [file, setFile] = useState([]);
  const [newarrivals, setNewarrivals] = useState(state.item.newarrivals);
  const [bestsellcheck, setBestsellcheck] = useState(state.item.bestseller);
  const [status, setStatus] = useState(state.item.status);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(state.item._id);
  const [allChildCategory, setAllChildCategory] = useState("");

  const navigate = useNavigate();

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [category, setCategory] = useState([]);
  const [allsubcategory, setAllSubCategory] = useState([]);
  const [homecategory, setHomeCategory] = useState([]);
  const [hcategory, setHcategory] = useState(state.item?.HomeCategoryId);
  const [ctgryId, setCtgryId] = useState(state.item?.CategoryId);
  const [scategory, setScategory] = useState(state.item?.SubCategoryId);
  const [childCategoryId, setChildCategoryId] = useState(
    state.item?.ChildCategoryId
  );

  const getAllCat = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getAllCategory`, {
        headers: {
          "x-access-token": `${token}`,
          version: "1.0.0",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          toast.error(response.data.message);
        } else {
          setCategory(response.data.data);
          console.log("getallcategory", response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getChildcat = () => {
    const token = localStorage.getItem("token");
    if (scategory) {
      axios
        .get(`${BASE_URL}admin/childcategorybysubcategory/${scategory}`, {
          headers: {
            "x-access-token": `${token}`,
            version: "1.0.0",
            "Content-Type": "Application/json",
          },
        })
        .then((response) => {
          if (response.data.success == false) {
            console.log(response.data.message);
            setAllChildCategory("");
          } else {
            setAllChildCategory(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };
  const UpdateProduct = () => {
    const token = localStorage.getItem("token");
    const fromData = new FormData();
    fromData.append("name", name);
    fromData.append("price", price);
    fromData.append("short_decription", shortdes);
    fromData.append("long_decription", longdes);

    for (let value of file) {
      fromData.append("image", value);
    }
    fromData.append("CategoryId", ctgryId);
    if (scategory) {
      fromData.append("SubCategoryId", scategory);
    }
    fromData.append("HomeCategoryId", hcategory);
    fromData.append("ChildCategoryId", childCategoryId);

    fromData.append("bestseller", bestsellcheck);
    fromData.append("newarrivals", newarrivals);
    fromData.append("status", status);
    fromData.append("quantity", quantity);
    setLoading(true);
    axios
      .put(`${BASE_URL}admin/updateProduct/${id}`, fromData, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
          const productVariantsWithProductId = newProductVarients.map(
            (variant) => ({
              ...variant,
              product_id: id,
            })
          );
          AddProductVarients(productVariantsWithProductId);
          toast.success(response.data.message);
          setName("");
          setPrice("");
          setShortdes("");
          setLongdes("");
          navigate("/ProductListing");
        } else {
          setLoading(false);
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);

        console.log(error.message);
      });
  };

  const AddProductVarients = (items) => {
    const token = localStorage.getItem("token");
    console.log("items", items);
    axios
      .post(`${BASE_URL}admin/addProductVarient`, items, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success === false) {
          console.log(response.data.message);
        } else {
          toast.success(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getSubCategory = () => {
    const token = localStorage.getItem("token");
    if (ctgryId) {
      axios
        .post(
          `${BASE_URL}admin/getSubCategoryByCategoryId/${ctgryId}`,
          {},
          {
            headers: {
              "x-access-token": `${token}`,
              "Content-Type": "Application/json",
              Version: "1.0.0",
            },
          }
        )
        .then(function (response) {
          if (response.data.success) {
            setAllSubCategory(response.data.data);
          } else {
            setAllSubCategory([]);
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    } else {
      console.log(
        "Category not defined. In this case cannot set the subcategory"
      );
    }
  };

  const getallhomecategory = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getAllHomeCategory`, {
        headers: {
          version: "1.0.0",
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setHomeCategory(response.data.data);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllCat();
    getallhomecategory();
    getChildcat();
  }, []);

  useEffect(() => {
    getSubCategory();
  }, [ctgryId]);

  const Fntosetsubc = (value, getSubCategory) => {
    setCtgryId(value);
    if (getSubCategory && typeof getSubCategory === "function") {
      setScategory("");

      getSubCategory();
    }
  };

  const getSizes = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getSize`, {
        headers: {
          "Content-Type": "Application/json",
          "x-access-token": `${token}`,
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success === false) {
          console.log(response.data.message);
        } else {
          setSizes(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getSizes();
  }, []);

  const EditVarient = (item) => {
    let btn = document.querySelector(".addeditPV");
    btn.innerHTML = "Edit Options";
    setSize(item.size);
    setFields(item.weightnprice);
    setVerityDef(item.set_default);
    setProductVId(item._id);
  };

  const addproductV = async () => {
    if (size == "" || size == undefined) {
      toast.error(" Size is required.");
      return;
    }

    let obj = {
      size: size,
      weightnprice: fields,
      set_default: verityDef,
    };
    newProductVarients.push(obj);
    setSize("");
    setVerityDef(false);
  };

  const AddEdit = () => {
    if (productVId) {
      UpdateProductV();
    } else {
      addproductV();
    }
  };

  const UpdateProductV = () => {
    const obj = {
      size: size,
      weightnprice: fields,
      set_default: verityDef,
    };

    const token = localStorage.getItem("token");
    axios
      .put(`${BASE_URL}admin/updateVarient/${productVId}`, obj, {
        headers: {
          "x-access-token": `${token}`,
          version: "1.0.0",
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.success === false) {
          toast.error(response.data.message);
        } else {
          console.log("updated result", response.data.data);
          toast.success(response.data.message);
          let btn = document.querySelector(".addeditPV");
          btn.innerHTML = "Add Options";
          setFields([]);
          setVprice("");
          setSize("");
          setWeight("");
          setProductVId("");
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const DeleteVarient = (id) => {
    const token = localStorage.getItem("token");
    axios
      .delete(`${BASE_URL}admin/deleteProductVarient/${id}`, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success === false) {
          toast.error(response.data.message);
        } else {
          toast.success(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const addField = () => {
    setFields([
      ...fields,
      { id: fields.length, weight: weight, price: vprice },
    ]);
    setWeight("");
    setVprice("");
  };

  const removeField = (id) => {
    const newFields = fields.filter(
      (field) => field.id !== id && field.id !== id + 1
    );
    setFields(newFields);
  };

  useEffect(() => {
    getChildcat();
  }, [scategory]);

  return (
    <>
      <NavBar />
      <div className="container ">
        <div className="row d-flex justify-content-center align-items-center mt-5">
          <div
            className="col-12 col-md-10 p-md-5 p-3"
            style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
          >
            <div className="row">
              <h2>Update Product</h2>
              <div className="col-md-6">
                <label className="p-2">Category</label>
                <select
                  name="category"
                  className="form-control"
                  value={ctgryId}
                  onChange={(e) => Fntosetsubc(e.target.value, getSubCategory)}
                >
                  <option value="">--select--</option>
                  {category.map((item, index) => (
                    <option value={item._id} key={index}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6">
                <label className="p-2">Subcategory</label>
                <select
                  name="subcatgeory"
                  className="form-control"
                  value={scategory}
                  onChange={(e) => setScategory(e.target.value)}
                >
                  <option value="">--select--</option>
                  {allsubcategory.map((item, index) => (
                    <option value={item._id} key={index}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-md-6">
                <label className="formleb"> ChildCategory</label>
                <select
                  className="form-control forminp"
                  name="childCateId"
                  value={childCategoryId}
                  onChange={(e) => setChildCategoryId(e.target.value)}
                >
                  <option value="">--select--</option>
                  {allChildCategory.length > 0 &&
                    allChildCategory.map((item, index) => (
                      <option value={item._id} key={index}>
                        {item.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-md-6">
                <label className="p-2">HomeCategory</label>
                <select
                  name="homecategory"
                  className="form-control"
                  value={hcategory}
                  onChange={(e) => setHcategory(e.target.value)}
                >
                  <option value="">--select--</option>
                  {homecategory.map((item, index) => (
                    <option value={item._id} key={index}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6">
                <label className="p-2">Product name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div className="col-md-6">
                <label className="p-2">Price</label>
                <input
                  type="text"
                  className="form-control"
                  name="price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label className="p-2">Quantity</label>
                <input
                  type="text"
                  className="form-control"
                  name="quantity"
                  value={quantity}
                  onChange={(e) => setquantity(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label className="p-2">Image</label>
                <input
                  type="file"
                  className="form-control"
                  name="file"
                  multiple
                  // value={file}
                  onChange={(e) => setFile(e.target.files)}
                />
                {state.item?.image?.map((item, index) => (
                  <>
                    <img
                      src={item}
                      className="img-fluid"
                      width={40}
                      height={30}
                    />
                    &nbsp;&nbsp;
                  </>
                ))}
              </div>
              <div className="col-md-6">
                <label className="p-2">Short description</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={shortdes}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    // console.log( 'Editor is ready to use!', editor );
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setShortdes(data);
                    // console.log( data  );
                  }}
                />
              </div>
              <div className="col-md-6">
                <label className="p-2">Long description</label>

                <CKEditor
                  editor={ClassicEditor}
                  data={longdes}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    // console.log( 'Editor is ready to use!', editor );
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setLongdes(data);
                    // console.log( data  );
                  }}
                />
              </div>

              <div className="col-md-6">
                <label className="p-2">Status</label>
                <select
                  name="status"
                  className="form-control"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="">--select--</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>
              <div className="col-md-6 p-3">
                <label className="formleb">
                  Check mark if you want to add this product in
                </label>
                <br />
                <div className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    className="largerCheckbox"
                    onClick={(e) => setBestsellcheck(e.target.checked)}
                    checked={bestsellcheck}
                  />
                  &nbsp;&nbsp;&nbsp; <span>Bestseller</span>&nbsp;&nbsp;&nbsp;
                  <input
                    type="checkbox"
                    className="largerCheckbox"
                    onClick={(e) => setNewarrivals(e.target.checked)}
                    checked={newarrivals}
                  />
                  &nbsp;&nbsp;&nbsp; <span>New Arrivals</span>
                </div>
              </div>
              <div className="col-md-12 d-none">
                <div className="">
                  <div>
                    <div className="row">
                      <div className="col-md-6 p-3">
                        <label>Select Size</label>
                        <select
                          className="form-control"
                          onChange={(e) => setSize(e.target.value)}
                          value={size}
                        >
                          <option className="" value="">
                            --select--
                          </option>
                          {sizes?.map((item, index) => (
                            <option
                              className=""
                              key={item._id}
                              value={item.name}
                            >
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div>
                        <button className="btn btn-primary" onClick={addField}>
                          <span className="fa fa-plus">add</span>
                        </button>

                        <div id="extend-field">
                          {fields.map((field) => (
                            <div className="row" key={field.id}>
                              <div className="col ">
                                <label>Weight</label>
                                <br />
                                <input
                                  type="text"
                                  value={field.weight}
                                  className="form-control"
                                  onChange={(e) =>
                                    setFields(
                                      fields.map((f) =>
                                        f.id === field.id
                                          ? { ...f, weight: e.target.value }
                                          : f
                                      )
                                    )
                                  }
                                />
                              </div>
                              <div className="col ">
                                <label>Price</label>
                                <br />
                                <input
                                  type="text"
                                  value={field.price}
                                  className="form-control"
                                  onChange={(e) =>
                                    setFields(
                                      fields.map((f) =>
                                        f.id === field.id
                                          ? { ...f, price: e.target.value }
                                          : f
                                      )
                                    )
                                  }
                                />
                              </div>
                              <div className="col-2">
                                <br />

                                <button onClick={() => removeField(field.id)}>
                                  Remove
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="col-md-6 p-3">
                        <label>
                          Check if you wanna set this varient as default?
                        </label>
                        <br />
                        <input
                          type="checkbox"
                          className="largerCheckbox"
                          placeholder="set to default"
                          onClick={(e) => setVerityDef(e.target.checked)}
                          checked={verityDef}
                        />
                      </div>
                      <div className="col-md-6 p-3"></div>
                    </div>
                  </div>
                  <button
                    className="btn btn-primary btn-sm p-2 addeditPV"
                    onClick={() => AddEdit()}
                  >
                    {" "}
                    Add Options
                  </button>
                </div>

                <br />
                <br />
                {/* </div> */}
                <div className="col d-none">
                  <div style={{ overflowX: "auto" }}>
                    <Table striped bordered hover>
                      {productvarient?.length > 0 ? (
                        <>
                          {" "}
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Size</th>
                              <th>Weight</th>

                              <th>Price</th>

                              <th>default</th>
                              <th>Edit</th>
                              <th>Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {productvarient.length > 0
                              ? productvarient.map((item, index) => (
                                  <tr key={item._id}>
                                    <td>{index + 1}</td>
                                    <td>{item.size}</td>
                                    <td>
                                      {item.weightnprice.length > 0 &&
                                        item.weightnprice.map(
                                          (item1, index1) => (
                                            <div key={index1}>
                                              {item1.weight}
                                            </div>
                                          )
                                        )}
                                    </td>
                                    <td>
                                      {item.weightnprice.length > 0 &&
                                        item.weightnprice.map(
                                          (item1, index1) => (
                                            <div key={index1}>
                                              {item1.price}
                                            </div>
                                          )
                                        )}
                                    </td>

                                    <td>
                                      {item.set_default ? "True" : "false"}
                                    </td>
                                    <td onClick={() => EditVarient(item)}>
                                      {item.product_id ? (
                                        <i className="fa fa-edit"></i>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    <td onClick={() => DeleteVarient(item._id)}>
                                      {item.product_id ? (
                                        <i className="fa fa-trash"></i>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                  </tr>
                                ))
                              : ""}
                          </tbody>
                        </>
                      ) : (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ height: "200px", width: "100%" }}
                        >
                          <div>No record Added</div>
                        </div>
                      )}
                    </Table>
                  </div>

                  {/* newly added varient  */}
                  {newProductVarients.length > 0 ? (
                    <>
                      <h3>Newly added varient</h3>
                      <div className="" style={{ overflowX: "auto" }}>
                        <Table striped bordered hover>
                          {newProductVarients?.length > 0 ? (
                            <>
                              {" "}
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Size</th>
                                  <th>Weight</th>

                                  <th>Price</th>

                                  <th>default</th>
                                </tr>
                              </thead>
                              <tbody>
                                {newProductVarients.length > 0
                                  ? newProductVarients.map((item, index) => (
                                      <tr key={item._id}>
                                        <td>{index + 1}</td>
                                        <td>{item.size}</td>
                                        <td>
                                          {item.weightnprice.length > 0 &&
                                            item.weightnprice.map(
                                              (item1, index1) => (
                                                <div key={index1}>
                                                  {item1.weight}
                                                </div>
                                              )
                                            )}
                                        </td>
                                        <td>
                                          {item.weightnprice.length > 0 &&
                                            item.weightnprice.map(
                                              (item1, index1) => (
                                                <div key={index1}>
                                                  {item1.price}
                                                </div>
                                              )
                                            )}
                                        </td>
                                        <td>
                                          {item.set_default ? "True" : "false"}
                                        </td>
                                      </tr>
                                    ))
                                  : ""}
                              </tbody>
                            </>
                          ) : (
                            <div
                              className="d-flex justify-content-center align-items-center"
                              style={{ height: "200px", width: "100%" }}
                            >
                              <div>No record Added</div>
                            </div>
                          )}
                        </Table>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <button
                type="submit"
                className="btn btn-sm btn-primary mt-3 ms-3"
                style={{ width: "60px" }}
                onClick={() => UpdateProduct()}
              >
                Update {loading && <Spinner animation="border" size="sm" />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UpdateProduct;
